import React from 'react'
import { getSettings } from './utils'

export const MainPageLoadingTemplate = (data) => {
    return (
      <div id="mainContentAllContainer">
        <section id='homeError'>
            <div id="errorTextContainer">
                <p id="errorLine1">Oops, something went wrong. Sorry about this.</p>
                <p id="errorLine2">Please refresh or alternatively <a href="mailto: &#115;&#105;&#109;&#111;&#110;&#098;&#111;&#114;&#114;&#111;&#117;&#103;&#104;&#064;&#109;&#101;&#046;&#099;&#111;&#109;" id="creditEmailLink"><strong>get in touch by email</strong></a>. Thanks.</p>
            </div>
        </section>
      </div>
    );
}

const MainPageLoading = () => {
    let settings = getSettings();

    let images = {
        backgrounds: {
            page1Background: ""
        },
        logos: {
            sbLogo1: ""
        }
    }

    let text = {
        p1: "Oops, something went wrong. Sorry about this.",
        p2: "Please refresh or alternatively send me a message describing the problem. Thanks."
    }

    return(
        <MainPageLoadingTemplate
            images={images}
            settings={settings}
            text={text}
        />
    );
};

export default MainPageLoading