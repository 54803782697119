export const getImages = (data) => {

  let maxImageWidth;

  if(
    window.screen.width > 1023 
  ) {
    maxImageWidth = "?w=" + window.screen.width;
  } else if(
    window.screen.width < 1023
    &&
    window.screen.width > 667
  ) {
    maxImageWidth = "?w=1023";
  } else if(
    window.screen.width < 667
  ) {
    maxImageWidth = "?w=850";
  }
  
  let images = {};

  images['logos'] = {
      sbLogo1: getImgRefUrl(data.firstPageHome.image, "?w=850"),
      sbLogo2: getImgRefUrl(data.lastPageContact.image, "?w=850")
  }

  images['backgrounds'] = {
    page1Background: getImgRefUrl(data.firstPageHome.backgroundImage, maxImageWidth),
    page2Background: getImgRefUrl(data.aboutMe.backgroundImage, maxImageWidth),
    page3Background: getImgRefUrl(data.childBooksSectionHeader.backgroundImage, maxImageWidth),
    page8Background: getImgRefUrl(data.adultsBooksSectionHeader.backgroundImage, maxImageWidth),
    page12Background: getImgRefUrl(data.guideBooksSectionHeader.backgroundImage, maxImageWidth),
    page19Background: getImgRefUrl(data.lastPageContact.backgroundImage, maxImageWidth),
  }

  images['aboutMe'] = {
    smaller: {
      image1: getImgRefUrl(data.aboutMe.image1, "?w=400"),
      image2: getImgRefUrl(data.aboutMe.image2, "?w=400")
    },
    tiny: {
      image1: getImgRefUrl(data.aboutMe.image1, "?h=100&blur=100&q=10"),
      image2: getImgRefUrl(data.aboutMe.image2, "?h=100&blur=100&q=10")
    },
    medium: {
      image1: getImgRefUrl(data.aboutMe.image1, "?w=850"),
      image2: getImgRefUrl(data.aboutMe.image2, "?w=850")
    },
    fullSize: {
      image1: getImgRefUrl(data.aboutMe.image1, "?w=2500"),
      image2: getImgRefUrl(data.aboutMe.image2, "?w=2500")
    }
  }

  images['actionForce'] = {
    smaller: {
      image1: getImgRefUrl(data.actionForce.image1, "?w=400"),
      image2: getImgRefUrl(data.actionForce.image2, "?h=400"),
      image3: getImgRefUrl(data.actionForce.image3, "?h=400"),
      image4: getImgRefUrl(data.actionForce.image4, "?h=400"),
      image5: getImgRefUrl(data.actionForce.image5, "?h=400"),
      image6: getImgRefUrl(data.actionForce.image6, "?h=400"),
      image7: getImgRefUrl(data.actionForce.image7, "?h=400"),
      image8: getImgRefUrl(data.actionForce.image8, "?h=400"),
      image9: getImgRefUrl(data.actionForce.image9, "?h=400")
    },
    tiny: {
      image1: getImgRefUrl(data.actionForce.image1, "?h=100&blur=100&q=10"),
      image2: getImgRefUrl(data.actionForce.image2, "?w=100&blur=100&q=10"),
      image3: getImgRefUrl(data.actionForce.image3, "?w=100&blur=100&q=10"),
      image4: getImgRefUrl(data.actionForce.image4, "?w=100&blur=100&q=10"),
      image5: getImgRefUrl(data.actionForce.image5, "?w=100&blur=100&q=10"),
      image6: getImgRefUrl(data.actionForce.image6, "?w=100&blur=100&q=10"),
      image7: getImgRefUrl(data.actionForce.image7, "?w=100&blur=100&q=10"),
      image8: getImgRefUrl(data.actionForce.image8, "?w=100&blur=100&q=10"),
      image9: getImgRefUrl(data.actionForce.image9, "?w=100&blur=100&q=10")
    },
    medium: {
      image1: getImgRefUrl(data.actionForce.image1, "?w=850"),
      image2: getImgRefUrl(data.actionForce.image2, "?h=850"),
      image3: getImgRefUrl(data.actionForce.image3, "?h=850"),
      image4: getImgRefUrl(data.actionForce.image4, "?h=850"),
      image5: getImgRefUrl(data.actionForce.image5, "?h=850"),
      image6: getImgRefUrl(data.actionForce.image6, "?h=850"),
      image7: getImgRefUrl(data.actionForce.image7, "?h=850"),
      image8: getImgRefUrl(data.actionForce.image8, "?h=850"),
      image9: getImgRefUrl(data.actionForce.image9, "?h=850")
    },
    fullSize: {
      image1: getImgRefUrl(data.actionForce.image1, "?w=2500"),
      image2: getImgRefUrl(data.actionForce.image2, "?w=2500"),
      image3: getImgRefUrl(data.actionForce.image3, "?w=2500"),
      image4: getImgRefUrl(data.actionForce.image4, "?w=2500"),
      image5: getImgRefUrl(data.actionForce.image5, "?w=2500"),
      image6: getImgRefUrl(data.actionForce.image6, "?w=2500"),
      image7: getImgRefUrl(data.actionForce.image7, "?w=2500"),
      image8: getImgRefUrl(data.actionForce.image8, "?w=2500"),
      image9: getImgRefUrl(data.actionForce.image9, "?w=2500")
    }
  }

  images['unstableEarth'] = {
      smaller: {
        image1: getImgRefUrl(data.unstableEarth.image1, "?h=400"),
        image2: getImgRefUrl(data.unstableEarth.image2, "?h=400"),
        image3: getImgRefUrl(data.unstableEarth.image3, "?h=400"),
        image4: getImgRefUrl(data.unstableEarth.image4, "?w=400"),
        image5: getImgRefUrl(data.unstableEarth.image5, "?w=400"),
        image6: getImgRefUrl(data.unstableEarth.image6, "?h=400"),
        image7: getImgRefUrl(data.unstableEarth.image7, "?w=400"),
        image8: getImgRefUrl(data.unstableEarth.image8, "?w=400")
      },
      tiny: {
        image1: getImgRefUrl(data.unstableEarth.image1, "?w=100&blur=100&q=10"),
        image2: getImgRefUrl(data.unstableEarth.image2, "?w=100&blur=100&q=10"),
        image3: getImgRefUrl(data.unstableEarth.image3, "?w=100&blur=100&q=10"),
        image4: getImgRefUrl(data.unstableEarth.image4, "?h=100&blur=100&q=10"),
        image5: getImgRefUrl(data.unstableEarth.image5, "?h=100&blur=100&q=10"),
        image6: getImgRefUrl(data.unstableEarth.image6, "?w=100&blur=100&q=10"),
        image7: getImgRefUrl(data.unstableEarth.image7, "?h=100&blur=100&q=10"),
        image8: getImgRefUrl(data.unstableEarth.image8, "?h=100&blur=100&q=10")
      },
      fullSize: {
          image1: getImgRefUrl(data.unstableEarth.image1, "?w=2500"),
          image2: getImgRefUrl(data.unstableEarth.image2, "?w=2500"),
          image3: getImgRefUrl(data.unstableEarth.image3, "?w=2500"),
          image4: getImgRefUrl(data.unstableEarth.image4, "?w=2500"),
          image5: getImgRefUrl(data.unstableEarth.image5, "?w=2500"),
          image6: getImgRefUrl(data.unstableEarth.image6, "?w=2500"),
          image7: getImgRefUrl(data.unstableEarth.image7, "?w=2500"),
          image8: getImgRefUrl(data.unstableEarth.image8, "?w=2500")
      },
      medium: {
        image1: getImgRefUrl(data.unstableEarth.image1, "?h=850"),
        image2: getImgRefUrl(data.unstableEarth.image2, "?h=850"),
        image3: getImgRefUrl(data.unstableEarth.image3, "?h=850"),
        image4: getImgRefUrl(data.unstableEarth.image4, "?w=850"),
        image5: getImgRefUrl(data.unstableEarth.image5, "?w=850"),
        image6: getImgRefUrl(data.unstableEarth.image6, "?h=850"),
        image7: getImgRefUrl(data.unstableEarth.image7, "?w=850"),
        image8: getImgRefUrl(data.unstableEarth.image8, "?w=850")
      }
  }

  images['cookingSkills'] = {
    smaller: {
      image1: getImgRefUrl(data.cookingSkills.image1, "?w=400"),
      image2: getImgRefUrl(data.cookingSkills.image2, "?w=400"),
      image3: getImgRefUrl(data.cookingSkills.image3, "?w=400"),
      image4: getImgRefUrl(data.cookingSkills.image4, "?w=400"),
      image5: getImgRefUrl(data.cookingSkills.image5, "?w=400"),
      image6: getImgRefUrl(data.cookingSkills.image6, "?w=400"),
      image7: getImgRefUrl(data.cookingSkills.image7, "?h=400"),
      image8: getImgRefUrl(data.cookingSkills.image8, "?h=400"),
      image9: getImgRefUrl(data.cookingSkills.image9, "?h=400"),
      image10: getImgRefUrl(data.cookingSkills.image10, "?h=400"),
      image11: getImgRefUrl(data.cookingSkills.image11, "?h=400"),
      image12: getImgRefUrl(data.cookingSkills.image12, "?h=400")
    },
    tiny: {
      image1: getImgRefUrl(data.cookingSkills.image1, "?h=100&blur=100&q=10"),
      image2: getImgRefUrl(data.cookingSkills.image2, "?h=100&blur=100&q=10"),
      image3: getImgRefUrl(data.cookingSkills.image3, "?h=100&blur=100&q=10"),
      image4: getImgRefUrl(data.cookingSkills.image4, "?h=100&blur=100&q=10"),
      image5: getImgRefUrl(data.cookingSkills.image5, "?h=100&blur=100&q=10"),
      image6: getImgRefUrl(data.cookingSkills.image6, "?h=100&blur=100&q=10"),
      image7: getImgRefUrl(data.cookingSkills.image7, "?w=100&blur=100&q=10"),
      image8: getImgRefUrl(data.cookingSkills.image8, "?w=100&blur=100&q=10"),
      image9: getImgRefUrl(data.cookingSkills.image9, "?w=100&blur=100&q=10"),
      image10: getImgRefUrl(data.cookingSkills.image10, "?w=100&blur=100&q=10"),
      image11: getImgRefUrl(data.cookingSkills.image11, "?w=100&blur=100&q=10"),
      image12: getImgRefUrl(data.cookingSkills.image12, "?w=100&blur=100&q=10")
    },
    medium: {
      image1: getImgRefUrl(data.cookingSkills.image1, "?w=850"),
      image2: getImgRefUrl(data.cookingSkills.image2, "?w=850"),
      image3: getImgRefUrl(data.cookingSkills.image3, "?w=850"),
      image4: getImgRefUrl(data.cookingSkills.image4, "?w=850"),
      image5: getImgRefUrl(data.cookingSkills.image5, "?w=850"),
      image6: getImgRefUrl(data.cookingSkills.image6, "?w=850"),
      image7: getImgRefUrl(data.cookingSkills.image7, "?h=850"),
      image8: getImgRefUrl(data.cookingSkills.image8, "?h=850"),
      image9: getImgRefUrl(data.cookingSkills.image9, "?h=850"),
      image10: getImgRefUrl(data.cookingSkills.image10, "?h=850"),
      image11: getImgRefUrl(data.cookingSkills.image11, "?h=850"),
      image12: getImgRefUrl(data.cookingSkills.image12, "?h=850")
    },
    fullSize: {
      image1: getImgRefUrl(data.cookingSkills.image1, "?w=2500"),
      image2: getImgRefUrl(data.cookingSkills.image2, "?w=2500"),
      image3: getImgRefUrl(data.cookingSkills.image3, "?w=2500"),
      image4: getImgRefUrl(data.cookingSkills.image4, "?w=2500"),
      image5: getImgRefUrl(data.cookingSkills.image5, "?w=2500"),
      image6: getImgRefUrl(data.cookingSkills.image6, "?w=2500"),
      image7: getImgRefUrl(data.cookingSkills.image7, "?w=2500"),
      image8: getImgRefUrl(data.cookingSkills.image8, "?w=2500"),
      image9: getImgRefUrl(data.cookingSkills.image9, "?w=2500"),
      image10: getImgRefUrl(data.cookingSkills.image10, "?w=2500"),
      image11: getImgRefUrl(data.cookingSkills.image11, "?w=2500"),
      image12: getImgRefUrl(data.cookingSkills.image12, "?w=2500")
    }
  }

  images['darkPast'] = {
    smaller: {
      image1: getImgRefUrl(data.darkPast.image1, "?h=400"),
      image2: getImgRefUrl(data.darkPast.image2, "?h=400"),
      image3: getImgRefUrl(data.darkPast.image3, "?h=400"),
      image4: getImgRefUrl(data.darkPast.image4, "?w=400"),
      image5: getImgRefUrl(data.darkPast.image5, "?h=400"),
      image6: getImgRefUrl(data.darkPast.image6, "?h=400")
    },
    tiny: {
      image1: getImgRefUrl(data.darkPast.image1, "?w=100&blur=100&q=10"),
      image2: getImgRefUrl(data.darkPast.image2, "?w=100&blur=100&q=10"),
      image3: getImgRefUrl(data.darkPast.image3, "?w=100&blur=100&q=10"),
      image4: getImgRefUrl(data.darkPast.image4, "?h=100&blur=100&q=10"),
      image5: getImgRefUrl(data.darkPast.image5, "?w=100&blur=100&q=10"),
      image6: getImgRefUrl(data.darkPast.image6, "?w=100&blur=100&q=10")
    },
    fullSize: {
      image1: getImgRefUrl(data.darkPast.image1, "?w=2500"),
      image2: getImgRefUrl(data.darkPast.image2, "?w=2500"),
      image3: getImgRefUrl(data.darkPast.image3, "?w=2500"),
      image4: getImgRefUrl(data.darkPast.image4, "?w=2500"),
      image5: getImgRefUrl(data.darkPast.image5, "?w=2500"),
      image6: getImgRefUrl(data.darkPast.image6, "?w=2500")
    },
    medium: {
      image1: getImgRefUrl(data.darkPast.image1, "?h=850"),
      image2: getImgRefUrl(data.darkPast.image2, "?h=850"),
      image3: getImgRefUrl(data.darkPast.image3, "?h=850"),
      image4: getImgRefUrl(data.darkPast.image4, "?w=850"),
      image5: getImgRefUrl(data.darkPast.image5, "?h=850"),
      image6: getImgRefUrl(data.darkPast.image6, "?h=850")
    }
  }

  images['calligraphy'] = {
    smaller: {
      image1: getImgRefUrl(data.calligraphy.image1, "?w=400"),
      image2: getImgRefUrl(data.calligraphy.image2, "?w=400"),
      image3: getImgRefUrl(data.calligraphy.image3, "?w=400"),
      image4: getImgRefUrl(data.calligraphy.image4, "?w=400")
    },
    tiny: {
      image1: getImgRefUrl(data.calligraphy.image1, "?h=100&blur=100&q=10"),
      image2: getImgRefUrl(data.calligraphy.image2, "?h=100&blur=100&q=10"),
      image3: getImgRefUrl(data.calligraphy.image3, "?h=100&blur=100&q=10"),
      image4: getImgRefUrl(data.calligraphy.image4, "?h=100&blur=100&q=10")
    },
    medium: {
      image1: getImgRefUrl(data.calligraphy.image1, "?w=850"),
      image2: getImgRefUrl(data.calligraphy.image2, "?w=850"),
      image3: getImgRefUrl(data.calligraphy.image3, "?w=850"),
      image4: getImgRefUrl(data.calligraphy.image4, "?w=850")
    },
    fullSize: {
      image1: getImgRefUrl(data.calligraphy.image1, "?w=2500"),
      image2: getImgRefUrl(data.calligraphy.image2, "?w=2500"),
      image3: getImgRefUrl(data.calligraphy.image3, "?w=2500"),
      image4: getImgRefUrl(data.calligraphy.image4, "?w=2500")
    }
  }

  images['hygge'] = {
    smaller: {
      image1: getImgRefUrl(data.hygge.image1, "?w=400"),
      image2: getImgRefUrl(data.hygge.image2, "?w=400"),
      image3: getImgRefUrl(data.hygge.image3, "?w=400")
    },
    tiny: {
      image1: getImgRefUrl(data.hygge.image1, "?h=100&blur=100&q=10"),
      image2: getImgRefUrl(data.hygge.image2, "?h=100&blur=100&q=10"),
      image3: getImgRefUrl(data.hygge.image3, "?h=100&blur=100&q=10")
    },
    fullSize: {
      image1: getImgRefUrl(data.hygge.image1, "?w=2500"),
      image2: getImgRefUrl(data.hygge.image2, "?w=2500"),
      image3: getImgRefUrl(data.hygge.image3, "?w=2500")
    },
    medium: {
      image1: getImgRefUrl(data.hygge.image1, "?w=850"),
      image2: getImgRefUrl(data.hygge.image2, "?w=850"),
      image3: getImgRefUrl(data.hygge.image3, "?w=850")
    }
  }

  images['silburyHill'] = {
    smaller: {
      image1: getImgRefUrl(data.silburyHill.image1, "?w=400"),
      image2: getImgRefUrl(data.silburyHill.image2, "?w=400"),
      image3: getImgRefUrl(data.silburyHill.image3, "?h=400")
    },
    tiny: {
      image1: getImgRefUrl(data.silburyHill.image1, "?h=100&blur=100&q=10"),
      image2: getImgRefUrl(data.silburyHill.image2, "?h=100&blur=100&q=10"),
      image3: getImgRefUrl(data.silburyHill.image3, "?w=100&blur=100&q=10")
    },
    fullSize: {
      image1: getImgRefUrl(data.silburyHill.image1, "?w=2500"),
      image2: getImgRefUrl(data.silburyHill.image2, "?w=2500"),
      image3: getImgRefUrl(data.silburyHill.image3, "?w=2500")
    },
    medium: {
      image1: getImgRefUrl(data.silburyHill.image1, "?w=850"),
      image2: getImgRefUrl(data.silburyHill.image2, "?w=850"),
      image3: getImgRefUrl(data.silburyHill.image3, "?h=850")
    }
  }

  images['navigatorGuides'] = {
    smaller: {
      image1: getImgRefUrl(data.navigatorGuides.image1, "?h=400"),
      image2: getImgRefUrl(data.navigatorGuides.image2, "?h=400"),
      image3: getImgRefUrl(data.navigatorGuides.image3, "?h=400"),
      image4: getImgRefUrl(data.navigatorGuides.image4, "?w=400"),
      image5: getImgRefUrl(data.navigatorGuides.image5, "?h=400"),
      image6: getImgRefUrl(data.navigatorGuides.image6, "?h=400"),
      image7: getImgRefUrl(data.navigatorGuides.image7, "?w=400")
    },
    tiny: {
      image1: getImgRefUrl(data.navigatorGuides.image1, "?w=100&blur=100&q=10"),
      image2: getImgRefUrl(data.navigatorGuides.image2, "?w=100&blur=100&q=10"),
      image3: getImgRefUrl(data.navigatorGuides.image3, "?w=100&blur=100&q=10"),
      image4: getImgRefUrl(data.navigatorGuides.image4, "?h=100&blur=100&q=10"),
      image5: getImgRefUrl(data.navigatorGuides.image5, "?w=100&blur=100&q=10"),
      image6: getImgRefUrl(data.navigatorGuides.image6, "?w=100&blur=100&q=10"),
      image7: getImgRefUrl(data.navigatorGuides.image7, "?h=100&blur=100&q=10")
    },
    fullSize: {
      image1: getImgRefUrl(data.navigatorGuides.image1, "?w=2500"),
      image2: getImgRefUrl(data.navigatorGuides.image2, "?w=2500"),
      image3: getImgRefUrl(data.navigatorGuides.image3, "?w=2500"),
      image4: getImgRefUrl(data.navigatorGuides.image4, "?w=2500"),
      image5: getImgRefUrl(data.navigatorGuides.image5, "?w=2500"),
      image6: getImgRefUrl(data.navigatorGuides.image6, "?w=2500"),
      image7: getImgRefUrl(data.navigatorGuides.image7, "?w=2500")
    },
    medium: {
      image1: getImgRefUrl(data.navigatorGuides.image1, "?h=850"),
      image2: getImgRefUrl(data.navigatorGuides.image2, "?h=850"),
      image3: getImgRefUrl(data.navigatorGuides.image3, "?h=850"),
      image4: getImgRefUrl(data.navigatorGuides.image4, "?w=850"),
      image5: getImgRefUrl(data.navigatorGuides.image5, "?h=850"),
      image6: getImgRefUrl(data.navigatorGuides.image6, "?h=850"),
      image7: getImgRefUrl(data.navigatorGuides.image7, "?w=850")
    }
  }

  images['wellsCathedral'] = {
    smaller: {
      image1: getImgRefUrl(data.wellsCathedral.image1, "?w=400"),
      image2: getImgRefUrl(data.wellsCathedral.image2, "?w=400"),
      image3: getImgRefUrl(data.wellsCathedral.image3, "?h=400"),
      image4: getImgRefUrl(data.wellsCathedral.image4, "?w=400"),
      image5: getImgRefUrl(data.wellsCathedral.image5, "?h=400")
    },
    tiny: {
      image1: getImgRefUrl(data.wellsCathedral.image1, "?h=100&blur=100&q=10"),
      image2: getImgRefUrl(data.wellsCathedral.image2, "?h=100&blur=100&q=10"),
      image3: getImgRefUrl(data.wellsCathedral.image3, "?w=100&blur=100&q=10"),
      image4: getImgRefUrl(data.wellsCathedral.image4, "?h=100&blur=100&q=10"),
      image5: getImgRefUrl(data.wellsCathedral.image5, "?w=100&blur=100&q=10")
    },
    fullSize: {
      image1: getImgRefUrl(data.wellsCathedral.image1, "?w=2500"),
      image2: getImgRefUrl(data.wellsCathedral.image2, "?w=2500"),
      image3: getImgRefUrl(data.wellsCathedral.image3, "?w=2500"),
      image4: getImgRefUrl(data.wellsCathedral.image4, "?w=2500"),
      image5: getImgRefUrl(data.wellsCathedral.image5, "?w=2500")
    },
    medium: {
      image1: getImgRefUrl(data.wellsCathedral.image1, "?w=850"),
      image2: getImgRefUrl(data.wellsCathedral.image2, "?w=850"),
      image3: getImgRefUrl(data.wellsCathedral.image3, "?h=850"),
      image4: getImgRefUrl(data.wellsCathedral.image4, "?w=850"),
      image5: getImgRefUrl(data.wellsCathedral.image5, "?h=850")
    }
  }

  images['hurstCastle'] = {
    smaller: {
      image1: getImgRefUrl(data.hurstCastle.image1, "?h=400"),
      image2: getImgRefUrl(data.hurstCastle.image2, "?w=400"),
      image3: getImgRefUrl(data.hurstCastle.image3, "?w=400"),
      image4: getImgRefUrl(data.hurstCastle.image4, "?w=400")
    },
    tiny: {
      image1: getImgRefUrl(data.hurstCastle.image1, "?w=100&blur=100&q=10"),
      image2: getImgRefUrl(data.hurstCastle.image2, "?h=100&blur=100&q=10"),
      image3: getImgRefUrl(data.hurstCastle.image3, "?h=100&blur=100&q=10"),
      image4: getImgRefUrl(data.hurstCastle.image4, "?h=100&blur=100&q=10")
    },
    fullSize: {
      image1: getImgRefUrl(data.hurstCastle.image1, "?w=2500"),
      image2: getImgRefUrl(data.hurstCastle.image2, "?w=2500"),
      image3: getImgRefUrl(data.hurstCastle.image3, "?w=2500"),
      image4: getImgRefUrl(data.hurstCastle.image4, "?w=2500")
    },
    medium: {
      image1: getImgRefUrl(data.hurstCastle.image1, "?h=850"),
      image2: getImgRefUrl(data.hurstCastle.image2, "?w=850"),
      image3: getImgRefUrl(data.hurstCastle.image3, "?w=850"),
      image4: getImgRefUrl(data.hurstCastle.image4, "?w=850")
    }
  }

  images['clovellyGuideBookRecipeBook'] = {
    smaller: {
      image1: getImgRefUrl(data.clovellyGuideBookRecipeBook.image1, "?h=400"),
      image2: getImgRefUrl(data.clovellyGuideBookRecipeBook.image2, "?w=400"),
      image3: getImgRefUrl(data.clovellyGuideBookRecipeBook.image3, "?w=400"),
      image4: getImgRefUrl(data.clovellyGuideBookRecipeBook.image4, "?h=400"),
      image5: getImgRefUrl(data.clovellyGuideBookRecipeBook.image5, "?w=400")
    },
    tiny: {
      image1: getImgRefUrl(data.clovellyGuideBookRecipeBook.image1, "?w=100&blur=100&q=10"),
      image2: getImgRefUrl(data.clovellyGuideBookRecipeBook.image2, "?h=100&blur=100&q=10"),
      image3: getImgRefUrl(data.clovellyGuideBookRecipeBook.image3, "?h=100&blur=100&q=10"),
      image4: getImgRefUrl(data.clovellyGuideBookRecipeBook.image4, "?w=100&blur=100&q=10"),
      image5: getImgRefUrl(data.clovellyGuideBookRecipeBook.image5, "?h=100&blur=100&q=10")
    },
    fullSize: {
      image1: getImgRefUrl(data.clovellyGuideBookRecipeBook.image1, "?w=2500"),
      image2: getImgRefUrl(data.clovellyGuideBookRecipeBook.image2, "?w=2500"),
      image3: getImgRefUrl(data.clovellyGuideBookRecipeBook.image3, "?w=2500"),
      image4: getImgRefUrl(data.clovellyGuideBookRecipeBook.image4, "?w=2500"),
      image5: getImgRefUrl(data.clovellyGuideBookRecipeBook.image5, "?w=2500")
    },
    medium: {
      image1: getImgRefUrl(data.clovellyGuideBookRecipeBook.image1, "?h=850"),
      image2: getImgRefUrl(data.clovellyGuideBookRecipeBook.image2, "?w=850"),
      image3: getImgRefUrl(data.clovellyGuideBookRecipeBook.image3, "?w=850"),
      image4: getImgRefUrl(data.clovellyGuideBookRecipeBook.image4, "?h=850"),
      image5: getImgRefUrl(data.clovellyGuideBookRecipeBook.image5, "?w=850")
    }
  }

  images['clovellyLeafletsNewsletter'] = {
    smaller: {
      image1: getImgRefUrl(data.clovellyLeafletsNewsletter.image1, "?h=400"),
      image2: getImgRefUrl(data.clovellyLeafletsNewsletter.image2, "?w=400"),
      image3: getImgRefUrl(data.clovellyLeafletsNewsletter.image3, "?w=400"),
      image4: getImgRefUrl(data.clovellyLeafletsNewsletter.image4, "?h=400"),
      image5: getImgRefUrl(data.clovellyLeafletsNewsletter.image5, "?h=400"),
      image6: getImgRefUrl(data.clovellyLeafletsNewsletter.image6, "?h=400"),
      image7: getImgRefUrl(data.clovellyLeafletsNewsletter.image7, "?h=400")
    },
    tiny: {
      image1: getImgRefUrl(data.clovellyLeafletsNewsletter.image1, "?w=100&blur=100&q=10"),
      image2: getImgRefUrl(data.clovellyLeafletsNewsletter.image2, "?h=100&blur=100&q=10"),
      image3: getImgRefUrl(data.clovellyLeafletsNewsletter.image3, "?h=100&blur=100&q=10"),
      image4: getImgRefUrl(data.clovellyLeafletsNewsletter.image4, "?w=100&blur=100&q=10"),
      image5: getImgRefUrl(data.clovellyLeafletsNewsletter.image5, "?w=100&blur=100&q=10"),
      image6: getImgRefUrl(data.clovellyLeafletsNewsletter.image6, "?w=100&blur=100&q=10"),
      image7: getImgRefUrl(data.clovellyLeafletsNewsletter.image7, "?w=100&blur=100&q=10")
    },
    fullSize: {
      image1: getImgRefUrl(data.clovellyLeafletsNewsletter.image1, "?w=2500"),
      image2: getImgRefUrl(data.clovellyLeafletsNewsletter.image2, "?w=2500"),
      image3: getImgRefUrl(data.clovellyLeafletsNewsletter.image3, "?w=2500"),
      image4: getImgRefUrl(data.clovellyLeafletsNewsletter.image4, "?w=2500"),
      image5: getImgRefUrl(data.clovellyLeafletsNewsletter.image5, "?w=2500"),
      image6: getImgRefUrl(data.clovellyLeafletsNewsletter.image6, "?w=2500"),
      image7: getImgRefUrl(data.clovellyLeafletsNewsletter.image7, "?w=2500")
    },
    medium: {
      image1: getImgRefUrl(data.clovellyLeafletsNewsletter.image1, "?h=850"),
      image2: getImgRefUrl(data.clovellyLeafletsNewsletter.image2, "?w=850"),
      image3: getImgRefUrl(data.clovellyLeafletsNewsletter.image3, "?w=850"),
      image4: getImgRefUrl(data.clovellyLeafletsNewsletter.image4, "?h=850"),
      image5: getImgRefUrl(data.clovellyLeafletsNewsletter.image5, "?h=850"),
      image6: getImgRefUrl(data.clovellyLeafletsNewsletter.image6, "?h=850"),
      image7: getImgRefUrl(data.clovellyLeafletsNewsletter.image7, "?h=850")
    }
  }

  images['infoGraphics'] = {
      smaller: {
        image1: getImgRefUrl(data.infoGraphics.image1, "?h=400"),
        image2: getImgRefUrl(data.infoGraphics.image2, "?h=400"),
        image3: getImgRefUrl(data.infoGraphics.image3, "?h=400"),
        image4: getImgRefUrl(data.infoGraphics.image4, "?h=400"),
        image5: getImgRefUrl(data.infoGraphics.image5, "?h=400"),
        image6: getImgRefUrl(data.infoGraphics.image6, "?h=400")
      },
      tiny: {
        image1: getImgRefUrl(data.infoGraphics.image1, "?w=100&blur=100&q=10"),
        image2: getImgRefUrl(data.infoGraphics.image2, "?w=100&blur=100&q=10"),
        image3: getImgRefUrl(data.infoGraphics.image3, "?w=100&blur=100&q=10"),
        image4: getImgRefUrl(data.infoGraphics.image4, "?w=100&blur=100&q=10"),
        image5: getImgRefUrl(data.infoGraphics.image5, "?w=100&blur=100&q=10"),
        image6: getImgRefUrl(data.infoGraphics.image6, "?w=100&blur=100&q=10")
      },
      fullSize: {
        image1: getImgRefUrl(data.infoGraphics.image1, "?w=2500"),
        image2: getImgRefUrl(data.infoGraphics.image2, "?w=2500"),
        image3: getImgRefUrl(data.infoGraphics.image3, "?w=2500"),
        image4: getImgRefUrl(data.infoGraphics.image4, "?w=2500"),
        image5: getImgRefUrl(data.infoGraphics.image5, "?w=2500"),
        image6: getImgRefUrl(data.infoGraphics.image6, "?w=2500")
      },
      medium: {
        image1: getImgRefUrl(data.infoGraphics.image1, "?h=850"),
        image2: getImgRefUrl(data.infoGraphics.image2, "?h=850"),
        image3: getImgRefUrl(data.infoGraphics.image3, "?h=850"),
        image4: getImgRefUrl(data.infoGraphics.image4, "?h=850"),
        image5: getImgRefUrl(data.infoGraphics.image5, "?h=850"),
        image6: getImgRefUrl(data.infoGraphics.image6, "?h=850")
      }
  }

  return images;
}

export const getText = (data) => {

    let text = {};

    text['aboutMe'] = {
        h1: data.aboutMe.h1,
        p1: data.aboutMe.p1,
        p2: data.aboutMe.p2,
        p3: data.aboutMe.p3,
        p4: data.aboutMe.p4,
        p5: data.aboutMe.p5,
        p6: data.aboutMe.p6,
        p7: data.aboutMe.p7,
        p8: data.aboutMe.p8,
        p9: data.aboutMe.p9,
        p10: data.aboutMe.p10,
        imgAlt1: data.aboutMe.imgAlt1,
        imgAlt2: data.aboutMe.imgAlt2
    };

    text['childBooksSectionHeader'] = {
        h1: data.childBooksSectionHeader.h1,
        imgAlt: data.childBooksSectionHeader.imgAlt
    }

    text['actionForce'] = {
        strongTitle: data.actionForce.strongTitle,
        p1: data.actionForce.p1,
        p2: data.actionForce.p2,
        imgAlt1: data.actionForce.imgAlt1,
        imgAlt2: data.actionForce.imgAlt2,
        imgAlt3: data.actionForce.imgAlt3,
        imgAlt4: data.actionForce.imgAlt4,
        imgAlt5: data.actionForce.imgAlt5,
        imgAlt6: data.actionForce.imgAlt6,
        imgAlt7: data.actionForce.imgAlt7,
        imgAlt8: data.actionForce.imgAlt8,
        imgAlt9: data.actionForce.imgAlt9
    }

    text['unstableEarth'] = {
        strongTitle: data.unstableEarth.strongTitle,
        p1: data.unstableEarth.p1,
        imgAlt1: data.unstableEarth.imgAlt1,
        imgAlt2: data.unstableEarth.imgAlt2,
        imgAlt3: data.unstableEarth.imgAlt3,
        imgAlt4: data.unstableEarth.imgAlt4,
        imgAlt5: data.unstableEarth.imgAlt5,
        imgAlt6: data.unstableEarth.imgAlt6,
        imgAlt7: data.unstableEarth.imgAlt7,
        imgAlt8: data.unstableEarth.imgAlt8
    }

    text['cookingSkills'] = {
        strongTitle: data.cookingSkills.strongTitle,
        p1: data.cookingSkills.p1,
        imgAlt1: data.cookingSkills.imgAlt1,
        imgAlt2: data.cookingSkills.imgAlt2,
        imgAlt3: data.cookingSkills.imgAlt3,
        imgAlt4: data.cookingSkills.imgAlt4,
        imgAlt5: data.cookingSkills.imgAlt5,
        imgAlt6: data.cookingSkills.imgAlt6,
        imgAlt7: data.cookingSkills.imgAlt7,
        imgAlt8: data.cookingSkills.imgAlt8,
        imgAlt9: data.cookingSkills.imgAlt9,
        imgAlt10: data.cookingSkills.imgAlt10,
        imgAlt11: data.cookingSkills.imgAlt11,
        imgAlt12: data.cookingSkills.imgAlt12
    }

    text['darkPast'] = {
        strongTitle: data.darkPast.strongTitle,
        p1: data.darkPast.p1,
        imgAlt1: data.darkPast.imgAlt1,
        imgAlt2: data.darkPast.imgAlt2,
        imgAlt3: data.darkPast.imgAlt3,
        imgAlt4: data.darkPast.imgAlt4,
        imgAlt5: data.darkPast.imgAlt5,
        imgAlt6: data.darkPast.imgAlt6
    }

    text['adultsBooksSectionHeader'] = {
        h1: data.adultsBooksSectionHeader.h1,
        imgAlt: data.adultsBooksSectionHeader.imgAlt
    }

    text['calligraphy'] = {
        strongTitle: data.calligraphy.strongTitle,
        p1: data.calligraphy.p1,
        imgAlt1: data.calligraphy.imgAlt1,
        imgAlt2: data.calligraphy.imgAlt2,
        imgAlt3: data.calligraphy.imgAlt3,
        imgAlt4: data.calligraphy.imgAlt4
    }

    text['hygge'] = {
        strongTitle: data.hygge.strongTitle,
        p1: data.hygge.p1,
        imgAlt1: data.hygge.imgAlt1,
        imgAlt2: data.hygge.imgAlt2,
        imgAlt3: data.hygge.imgAlt3
    }

    text['silburyHill'] = {
        strongTitle: data.silburyHill.strongTitle,
        p1: data.silburyHill.p1,
        imgAlt1: data.silburyHill.imgAlt1,
        imgAlt2: data.silburyHill.imgAlt2,
        imgAlt3: data.silburyHill.imgAlt3
    }

    text['guideBooksSectionHeader'] = {
        h1: data.guideBooksSectionHeader.h1,
        imgAlt: data.guideBooksSectionHeader.imgAlt
    }

    text['navigatorGuides'] = {
        strongTitle: data.navigatorGuides.strongTitle,
        p1: data.navigatorGuides.p1,
        imgAlt1: data.navigatorGuides.imgAlt1,
        imgAlt2: data.navigatorGuides.imgAlt2,
        imgAlt3: data.navigatorGuides.imgAlt3,
        imgAlt4: data.navigatorGuides.imgAlt4,
        imgAlt5: data.navigatorGuides.imgAlt5,
        imgAlt6: data.navigatorGuides.imgAlt6,
        imgAlt7: data.navigatorGuides.imgAlt7
    }

    text['wellsCathedral'] = {
        strongTitle: data.wellsCathedral.strongTitle,
        p1: data.wellsCathedral.p1,
        imgAlt1: data.wellsCathedral.imgAlt1,
        imgAlt2: data.wellsCathedral.imgAlt2,
        imgAlt3: data.wellsCathedral.imgAlt3,
        imgAlt4: data.wellsCathedral.imgAlt4,
        imgAlt5: data.wellsCathedral.imgAlt5
    }

    text['hurstCastle'] = {
        strongTitle: data.hurstCastle.strongTitle,
        strongTitle2: data.hurstCastle.strongTitle2,
        p1: data.hurstCastle.p1,
        p2: data.hurstCastle.p2,
        imgAlt1: data.hurstCastle.imgAlt1,
        imgAlt2: data.hurstCastle.imgAlt2,
        imgAlt3: data.hurstCastle.imgAlt3,
        imgAlt4: data.hurstCastle.imgAlt4
    }

    text['clovellyGuideBookRecipeBook'] = {
        strongTitle: data.clovellyGuideBookRecipeBook.strongTitle,
        p1: data.clovellyGuideBookRecipeBook.p1,
        strongTitle2: data.clovellyGuideBookRecipeBook.strongTitle2,
        imgAlt1: data.clovellyGuideBookRecipeBook.imgAlt1,
        imgAlt2: data.clovellyGuideBookRecipeBook.imgAlt2,
        imgAlt3: data.clovellyGuideBookRecipeBook.imgAlt3,
        imgAlt4: data.clovellyGuideBookRecipeBook.imgAlt4,
        imgAlt5: data.clovellyGuideBookRecipeBook.imgAlt5
    }

    text['clovellyLeafletsNewsletter'] = {
        strongTitle: data.clovellyLeafletsNewsletter.strongTitle,
        p1: data.clovellyLeafletsNewsletter.p1,
        strongTitle2: data.clovellyLeafletsNewsletter.strongTitle2,
        p2: data.clovellyLeafletsNewsletter.p2,
        imgAlt1: data.clovellyLeafletsNewsletter.imgAlt1,
        imgAlt2: data.clovellyLeafletsNewsletter.imgAlt2,
        imgAlt3: data.clovellyLeafletsNewsletter.imgAlt3,
        imgAlt4: data.clovellyLeafletsNewsletter.imgAlt4,
        imgAlt5: data.clovellyLeafletsNewsletter.imgAlt5,
        imgAlt6: data.clovellyLeafletsNewsletter.imgAlt6,
        imgAlt7: data.clovellyLeafletsNewsletter.imgAlt7
    }

    text['infoGraphics'] = {
        strongTitle: data.infoGraphics.strongTitle,
        imgAlt1: data.infoGraphics.imgAlt1,
        imgAlt2: data.infoGraphics.imgAlt2,
        imgAlt3: data.infoGraphics.imgAlt3,
        imgAlt4: data.infoGraphics.imgAlt4,
        imgAlt5: data.infoGraphics.imgAlt5,
        imgAlt6: data.infoGraphics.imgAlt6
    }
    
    return text;
}

const getImgRefUrl = (image, dimensionParam) => {

  let imgUrl;

  if(image.asset === undefined) {
    imgUrl = "";
  } else {
    let baseUrlBegin = "https://cdn.sanity.io/images/tvwu98cv/production/"
  
    let imgRef = image.asset._ref
    let splitImgRef = imgRef.split("-", 4);
    let imgRefNew = splitImgRef[1] + "-" + splitImgRef[2];
    let fileExt = splitImgRef[3]
    let baseUrlEnd = "." + fileExt;
  
    imgUrl = baseUrlBegin + imgRefNew + baseUrlEnd + dimensionParam;
  }

  return imgUrl;
}