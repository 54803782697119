import React, { Component } from 'react'
import { scrollControl } from './utils'
import Scrollchor from 'react-scrollchor';

const duration = 650;

const getWideNav = (logo, childBookHeading, adultsBookHeading, guideBookHeading) => {
    return (
        <div id="navBarScrollableAnchorContainer">
            <div id="navBarScrollableAnchor">
                <div id="navBarScrollableAnchorLeft">
                    <Scrollchor to='#home' animate={{duration: duration}}>
                        <img src={logo} id="navLogo" alt="Simon Borrough Book Design logo"/>
                    </Scrollchor>
                </div>
                <div id="navBarScrollableAnchorRight">
                    <div id="navBarScrollableAnchorRightNavLinksContainer">
                        <ul>
                            <li><Scrollchor to='#about' animate={{duration: duration}}>About</Scrollchor></li>
                            <li><Scrollchor to='#booksChildren' animate={{duration: duration}}>{childBookHeading}</Scrollchor></li>
                            <li><Scrollchor to='#booksAdults' animate={{duration: duration}}>{adultsBookHeading}</Scrollchor></li>
                            <li><Scrollchor to='#booksGuide' animate={{duration: duration}}>{guideBookHeading}</Scrollchor></li>
                            <li><Scrollchor to='#infoGraphics' animate={{duration: duration}}>Info Graphics</Scrollchor></li>
                            <li><Scrollchor to='#contact' animate={{duration: duration}}>Contact</Scrollchor></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getNarrowNav = (logo, childBookHeading, adultsBookHeading, guideBookHeading, status, handleMenuToggle) => {
    return (
        <div id="navBarScrollableAnchorContainer">
            <div id="navBarScrollableAnchor">
                <div id="navBarScrollableAnchorLeft">
                    <Scrollchor to='#home' animate={{duration: duration}}>
                        <img src={logo} id="navLogo" alt="Simon Borrough Book Design logo"/>
                    </Scrollchor>
                </div>
                <div id="navBarScrollableAnchorRight">
                    <div id="navBarScrollableAnchorRightNavLinksContainerNarrow">
                        <div id="navIconContainer">
                            <div id="nav-icon4" className={status} onClick={handleMenuToggle}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <ul id="narrowNavDropDown" className={status}>
                            <li onClick={handleMenuToggle}><Scrollchor to='#about' animate={{duration: duration}}>About</Scrollchor></li>
                            <li onClick={handleMenuToggle}><Scrollchor to='#booksChildren' animate={{duration: duration}}>{childBookHeading}</Scrollchor></li>
                            <li onClick={handleMenuToggle}><Scrollchor to='#booksAdults' animate={{duration: duration}}>{adultsBookHeading}</Scrollchor></li>
                            <li onClick={handleMenuToggle}><Scrollchor to='#booksGuide' animate={{duration: duration}}>{guideBookHeading}</Scrollchor></li>
                            <li onClick={handleMenuToggle}><Scrollchor to='#infoGraphics' animate={{offset: 155, duration: duration}}>Info Graphics</Scrollchor></li>
                            <li onClick={handleMenuToggle}><Scrollchor to='#contact' animate={{duration: duration}}>Contact</Scrollchor></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
/*
const getWideNav = (logo, childBookHeading, adultsBookHeading, guideBookHeading) => {
    return (
        <div id="navBarScrollableAnchorContainer">
            <div id="navBarScrollableAnchor">
                <div id="navBarScrollableAnchorLeft">
                    <AnchorLink href='#home'>
                        <img src={logo} id="navLogo"/>
                    </AnchorLink>
                </div>
                <div id="navBarScrollableAnchorRight">
                    <div id="navBarScrollableAnchorRightNavLinksContainer">
                        <ul>
                            <li><AnchorLink href='#about'>About</AnchorLink></li>
                            <li><AnchorLink href='#booksChildren'>{childBookHeading}</AnchorLink></li>
                            <li><AnchorLink href='#booksAdults'>{adultsBookHeading}</AnchorLink></li>
                            <li><AnchorLink href='#booksGuide'>{guideBookHeading}</AnchorLink></li>
                            <li><AnchorLink href='#infoGraphics'>Info Graphics</AnchorLink></li>
                            <li><AnchorLink href='#contact'>Contact</AnchorLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
*/
/*
const getNarrowNav = (logo, childBookHeading, adultsBookHeading, guideBookHeading, status, handleMenuToggle) => {
    return (
        <div id="navBarScrollableAnchorContainer">
            <div id="navBarScrollableAnchor">
                <div id="navBarScrollableAnchorLeft">
                    <AnchorLink href='#home'>
                        <img src={logo} id="navLogo"/>
                    </AnchorLink>
                </div>
                <div id="navBarScrollableAnchorRight">
                    <div id="navBarScrollableAnchorRightNavLinksContainerNarrow">
                        <div id="navIconContainer">
                            <div id="nav-icon4" className={status} onClick={handleMenuToggle}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <ul id="narrowNavDropDown" className={status}>
                            <li onClick={handleMenuToggle}><AnchorLink href='#about'>About</AnchorLink></li>
                            <li onClick={handleMenuToggle}><AnchorLink href='#booksChildren'>{childBookHeading}</AnchorLink></li>
                            <li onClick={handleMenuToggle}><AnchorLink href='#booksAdults'>{adultsBookHeading}</AnchorLink></li>
                            <li onClick={handleMenuToggle}><AnchorLink href='#booksGuide'>{guideBookHeading}</AnchorLink></li>
                            <li onClick={handleMenuToggle}><AnchorLink href='#infoGraphics'>Info Graphics</AnchorLink></li>
                            <li onClick={handleMenuToggle}><AnchorLink href='#contact'>Contact</AnchorLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
*/
export default class Nav extends Component {
    constructor (props) {
        super(props);

        this.state = {
            menu: 'closed'
        }

        this.handleMenuToggle = this.handleMenuToggle.bind(this);

        scrollControl();    
    }

    handleMenuToggle() {

        let status;

        if(this.state.menu === "closed") {
            status = "open";
        } else {
            status = "closed";
        }

        let narrowNav = getNarrowNav(this.props.logo, this.props.childBookHeading, this.props.adultsBookHeading, this.props.guideBookHeading, status, this.handleMenuToggle);

        this.setState({
            narrowNav: narrowNav,
            navSetting: narrowNav,
            menu: status
        })
    }

    handleResize() {
        let navSetting;

        if(window.innerWidth >= 1500) {
            navSetting = this.state.wideNav
        } else {
            navSetting = this.state.narrowNav
        }

        this.setState({
            navSetting: navSetting
        })
    }

    componentWillMount() {        
        let wideNav = getWideNav(this.props.logo, this.props.childBookHeading, this.props.adultsBookHeading, this.props.guideBookHeading);
        let narrowNav = getNarrowNav(this.props.logo, this.props.childBookHeading, this.props.adultsBookHeading, this.props.guideBookHeading, "closed",  this.handleMenuToggle);

        let navSetting;

        if(window.innerWidth >= 1500) {
            navSetting = wideNav
        } else {
            navSetting = narrowNav
        }

        this.setState({
            wideNav: wideNav,
            narrowNav: narrowNav,
            navSetting: navSetting
        })
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize.bind(this));
    }

    render() {
        return (
            this.state.navSetting
        )
    }
} 