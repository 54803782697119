import React from 'react';
import Lightbox from 'react-image-lightbox';
import disableScroll from 'disable-scroll';
import { getContentAndImagesDict } from './Layouts'
import { getLightboxIndex } from './utils'

export const PageSizePanelTemplate = ({ panelStyle, id, content, panelId }) => { return (
        <div className="pageSizePanel" id={panelId} style={panelStyle}>
            <div className="panelContentContainer" id={id}>
                {content.layout}
            </div>
        </div>
    );
}

class PageSizePanel extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
            imgSrcsArray: []
        }

        this.handleImageClick = this.handleImageClick.bind(this);
    }

    handleImageClick(e) {
        let lightboxIndex = getLightboxIndex(e.target.id);

        this.setState({
            isOpen: true,
            photoIndex: lightboxIndex
        })
    }

    componentWillMount() {

        let contentAndImagesDict = getContentAndImagesDict(this.props.id, this.props.images, this.handleImageClick, this.props.text, this.props.backgrounds);
        
        this.setState({
            content: contentAndImagesDict.content,
            imageSrcs: contentAndImagesDict.imageSrcs,
            imgSrcsArray: contentAndImagesDict.imgSrcsArray,
            arrayOfObjectsForReactImage: contentAndImagesDict.arrayOfObjectsForReactImage,
            panelId: contentAndImagesDict.panelId
        })
    }
    
    render() {

        if(this.state.isOpen) {
            disableScroll.on();
        } else {
            disableScroll.off();
        }

        let minHeightStyle = {
            minHeight: "fit-content"
        }

        return (
            <div>
                <PageSizePanelTemplate 
                    panelStyle={minHeightStyle}
                    id={this.props.id}
                    content={this.state.content}
                    panelId={this.state.panelId}
                />
                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={this.state.imgSrcsArray[this.state.photoIndex]}
                        nextSrc={this.state.imgSrcsArray[(this.state.photoIndex + 1) % this.state.imgSrcsArray.length]}
                        prevSrc={this.state.imgSrcsArray[(this.state.photoIndex + this.state.imgSrcsArray.length - 1) % this.state.imgSrcsArray.length]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + this.state.imgSrcsArray.length - 1) % this.state.imgSrcsArray.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % this.state.imgSrcsArray.length,
                        })
                        }
                    />
                    )}
            </div>
        );
    }
}

export default PageSizePanel