import React from 'react'
import LazyLoad from 'react-lazyload';

const lazyload = 1440 * 4;
const lazyLoadHeight = "343.7px";
const resize = true;

export const HeadingPanelTemplate = ({ panelStyle, id, caption, backgroundImage }) => {
    let style = {
        backgroundImage: "url(" + backgroundImage + ")"
    }
    
    return (
        <div className="headingPanel" style={panelStyle}>
            <LazyLoad 
            height={lazyLoadHeight}
            offset={lazyload}
            resize={resize}
            throttle={100}
            once={true}>
                <div className="panelContentContainer" id={id} style={style}>
                    <div id="panelContentContainerTextContainer">
                        <h1>{caption}</h1>
                    </div>
                </div>
            </LazyLoad>
        </div>
    );
  }

const HeadingPanel = (props) => {  
    return(
        <HeadingPanelTemplate 
            panelStyle={props.panelStyle}
            id={props.id}
            caption={props.caption}
            backgroundImage={props.imgSrc}
        />
    );
};

export default HeadingPanel