//App.js

export const getSanityClient = () => {
    const sanityClient = require('@sanity/client')
    const client = sanityClient({
        projectId: 'tvwu98cv',
        dataset: 'production',
        useCdn: true
    })

    return client
    .fetch(
        '*[_type in ["aboutMe", "sectionHeader", "sectionItem", "firstPageLastPage"]]',
    )
    .then(res => {
        return res;
    })
    .catch(err => {
        console.error('Oh no, error occured: ', err)
    })
}

export const getObjFromSanityCmsCall = (data) => {

    let newObj = {};
  
    for (let i = 0; i < data.length; i++) { 
      newObj[data[i].key] = data[i];
    }  
  
    return newObj;
}

//MainPage.js

export const getSettings = () => {
    let pageSizePanelStyle = getPageSizePanelStyle();
    let headingPanelStyle = getHeadingPanelStyle(pageSizePanelStyle.minHeight);

    let settings = {
        headingPanelStyle: headingPanelStyle,
        pageSizePanelStyle: pageSizePanelStyle
    }

    return settings
}

const getPageSizePanelStyle = () => {
    let minPanelHeight;

    if(window.screen.height > 1031) {
        minPanelHeight = window.screen.height - 205;
    }

    if(window.screen.width < 1367) {
        minPanelHeight = null;
    }

    let pageSizePanelStyle = {
        minHeight: minPanelHeight
    }

    return pageSizePanelStyle
}

const getHeadingPanelStyle = (pageSizePanelMinHeight) => {
    let headingPanelHeight = pageSizePanelMinHeight * 0.35;

    let headingPanelStyle = {
        minHeight: headingPanelHeight
    }

    return headingPanelStyle  
}

//Nav.js

export const scrollControl = () => {
    if (typeof window !== 'undefined') {
        let prevScrollpos = window.pageYOffset;
        window.onscroll = function () {
            const maxScroll = document.body.clientHeight - window.innerHeight;
            let currentScrollPos = window.pageYOffset;
            if(
                (
                    maxScroll > 0 
                    && 
                    prevScrollpos > currentScrollPos
                    && 
                    prevScrollpos <= maxScroll
                )
                || 
                (
                    maxScroll <= 0 
                    && 
                    prevScrollpos > currentScrollPos
                )
                ||
                (
                    prevScrollpos <= 4 
                    && 
                    currentScrollPos <= 4
                )
                ||
                (
                    currentScrollPos >= maxScroll - 4
                )
            ) {
                document.getElementById("navBarScrollableAnchorContainer").style.top = "0";
            } else {
                document.getElementById("navBarScrollableAnchorContainer").style.top = "-17.0rem";
            }
            prevScrollpos = currentScrollPos;
        }

    }
}

//PageSizePanel.js

export const getLightboxIndex = (id) => {
    let splitId = Number(id.split('-slide-')[1]) - 1;
    return splitId;
}