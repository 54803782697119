import React from 'react'
import HeadingPanel from './HeadingPanel'
import PageSizePanel from './PageSizePanel'
import Nav from './Nav'

import { getSettings } from './utils'

const getNav = (text, logo) => {
    return (
        <Nav 
            childBookHeading={text.childBooksSectionHeader.h1}
            adultsBookHeading={text.adultsBooksSectionHeader.h1}
            guideBookHeading={text.guideBooksSectionHeader.h1}
            logo={logo}
        />
    );
}

const getHomeSection = (pageSizePanelStyle, logos, backgrounds) => {
    return (
        <section id='home'>
            <PageSizePanel  
                panelStyle={pageSizePanelStyle}
                id="page1"
                images={logos}
                backgrounds={backgrounds}
            />
        </section>
    );
}

const getAboutSection = (pageSizePanelStyle, text, backgrounds, images) => {

    if(window.innerWidth < 1001) {
        pageSizePanelStyle = {
            minHeight: "fit-content"
        }
    }

    return (
        <section id='about'>
            <PageSizePanel  
                panelStyle={pageSizePanelStyle}
                id="page2"
                text={text}
                backgrounds={backgrounds}
                images={images}
            />
        </section>
    );
}

const getChildBookSection = (settings, images, text) => {
    return (
        <section id='booksChildren'>
            <HeadingPanel  
                panelStyle={settings.headingPanelStyle}
                id="page3"
                imgSrc={images.backgrounds.page3Background}
                imgAlt={text.childBooksSectionHeader.imgAlt}
                caption={text.childBooksSectionHeader.h1}
            />
            <PageSizePanel  
                panelStyle={settings.pageSizePanelStyle}
                id="page4"
                images={images.actionForce}
                text={text.actionForce}
            />
            <PageSizePanel  
                panelStyle={settings.pageSizePanelStyle}
                id="page5"
                images={images.unstableEarth}
                text={text.unstableEarth}
            />
            <PageSizePanel  
                panelStyle={settings.pageSizePanelStyle}
                id="page6"
                images={images.cookingSkills}
                text={text.cookingSkills}
            />
            <PageSizePanel  
                panelStyle={settings.pageSizePanelStyle}
                id="page7"
                images={images.darkPast}
                text={text.darkPast}
            />
        </section>
    );
}

const getAdultsBookSection = (settings, images, text) => {
    return (
        <section id='booksAdults'>
            <HeadingPanel  
                panelStyle={settings.headingPanelStyle}
                id="page8"
                imgSrc={images.backgrounds.page8Background}
                imgAlt={text.adultsBooksSectionHeader.imgAlt}
                caption={text.adultsBooksSectionHeader.h1}
            />
            <PageSizePanel  
                panelStyle={settings.pageSizePanelStyle}
                id="page9"
                images={images.calligraphy}
                text={text.calligraphy}
            />
            <PageSizePanel  
                panelStyle={settings.pageSizePanelStyle}
                id="page10"
                images={images.hygge}
                text={text.hygge}
            />
            <PageSizePanel  
                panelStyle={settings.pageSizePanelStyle}
                id="page11"
                images={images.silburyHill}
                text={text.silburyHill}
            />
        </section>
    );
}

const getGuideBookSection = (settings, images, text) => {
    return (
        <section id='booksGuide'>
            <HeadingPanel  
                panelStyle={settings.headingPanelStyle}
                id="page12"
                imgSrc={images.backgrounds.page12Background}
                imgAlt={text.guideBooksSectionHeader.imgAlt}
                caption={text.guideBooksSectionHeader.h1}
            />
            <PageSizePanel  
                panelStyle={settings.pageSizePanelStyle}
                id="page13"
                images={images.navigatorGuides}
                text={text.navigatorGuides}
            />
            <PageSizePanel  
                panelStyle={settings.pageSizePanelStyle}
                id="page14"
                images={images.wellsCathedral}
                text={text.wellsCathedral}
            />
            <PageSizePanel  
                panelStyle={settings.pageSizePanelStyle}
                id="page15"
                images={images.hurstCastle}
                text={text.hurstCastle}
            />
            <PageSizePanel  
                panelStyle={settings.pageSizePanelStyle}
                id="page16"
                images={images.clovellyGuideBookRecipeBook}
                text={text.clovellyGuideBookRecipeBook}
            />
            <PageSizePanel  
                panelStyle={settings.pageSizePanelStyle}
                id="page17"
                images={images.clovellyLeafletsNewsletter}
                text={text.clovellyLeafletsNewsletter}
            />
        </section>
    );
}

const getInfoGraphicSection = (pageSizePanelStyle, images, text) => {
    return (
        <section id='infoGraphics'>
            <PageSizePanel  
                panelStyle={pageSizePanelStyle}
                id="page18"
                images={images}
                text={text}
            />
        </section>
    );
}

const getContactSection = (pageSizePanelStyle, images, backgrounds) => {
    return (
        <section id='contact'>
            <PageSizePanel  
                panelStyle={pageSizePanelStyle}
                id="page19"
                images={images}
                backgrounds={backgrounds}
            />
        </section>
    );
}

const MainPageTemplate = (data) => {
    return (
        <div id="mainContentAllContainer">
            {data.nav}
            {data.homeSection}
            {data.aboutSection}
            {data.childBookSection}
            {data.adultsBookSection}
            {data.guideBookSection}
            {data.infoGraphicSection}
            {data.contactSection}
        </div>
    );
}

const getMainPageTemplate = (
    nav,
    homeSection,
    aboutSection,
    childBookSection,
    adultsBookSection,
    guideBookSection,
    infoGraphicSection,
    contactSection
) => {
    return(
        <MainPageTemplate
            nav={nav}
            homeSection={homeSection}
            aboutSection={aboutSection}
            childBookSection={childBookSection}
            adultsBookSection={adultsBookSection}
            guideBookSection={guideBookSection}
            infoGraphicSection={infoGraphicSection}
            contactSection={contactSection}
        />
    );
}

const MainPage = (data) => {

    let settings = getSettings();

    let homeSection = getHomeSection(
        settings.pageSizePanelStyle, 
        data.images.logos, 
        data.images.backgrounds
    );

    let aboutSection = getAboutSection(
        settings.pageSizePanelStyle, 
        data.text.aboutMe, 
        data.images.backgrounds,
        data.images.aboutMe
    );

    let childBookSection = getChildBookSection(
        settings, 
        data.images, 
        data.text
    );

    let adultsBookSection = getAdultsBookSection(
        settings, 
        data.images, 
        data.text
    );

    let guideBookSection = getGuideBookSection(
        settings, 
        data.images, 
        data.text
    );

    let infoGraphicSection = getInfoGraphicSection(
        settings.pageSizePanelStyle, 
        data.images.infoGraphics, 
        data.text.infoGraphics
    );

    let contactSection = getContactSection(
        settings.pageSizePanelStyle, 
        data.images.logos, 
        data.images.backgrounds
    );

    let mainPage;
    let nav;

    if(data.mobile === true) {
        nav = getNav(
            data.text, 
            data.images.logos.sbLogo1
        );

        mainPage = getMainPageTemplate(
            nav,
            homeSection,
            aboutSection,
            childBookSection,
            adultsBookSection,
            guideBookSection,
            infoGraphicSection,
            contactSection
        );
    } else {
        nav = getNav(
            data.text, 
            data.images.logos.sbLogo1
        );

        mainPage = getMainPageTemplate(
            nav,
            homeSection,
            aboutSection,
            childBookSection,
            adultsBookSection,
            guideBookSection,
            infoGraphicSection,
            contactSection
        );
    }

    return mainPage;
};

export default MainPage