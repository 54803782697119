import React from 'react'
import LazyLoad from 'react-lazyload';

const lazyload = 1440 * 4;
const lazyLoadHeight = "";
const resize = true;
const throttle = 0;
const onceSetting = false;

export const getContentAndImagesDict = (id, imageSrcs, handleImageClick, text, backgrounds) => {
    let content;
    let layout;
    let imgSrcsArray;
    let arrayOfObjectsForReactImage;

    if(
        id === "page1"
        ||
        id === "page19"
    ) {
        layout = getLayoutForPage(id, imageSrcs, null, text, backgrounds);

        content = {
            layout: layout
        };
    } else if(id === "page2") {
        layout = getLayoutForPage(id, imageSrcs, handleImageClick, text, backgrounds);
        
        imgSrcsArray = Object.values(imageSrcs.fullSize);
        arrayOfObjectsForReactImage = getArrayOfObjectsForReactImage(imgSrcsArray);

        content = {
            layout: layout
        };  
    } else if(typeof id === "string") {
        layout = getLayoutForPage(id, imageSrcs, handleImageClick, text, null);
        
        imgSrcsArray = Object.values(imageSrcs.fullSize);
        arrayOfObjectsForReactImage = getArrayOfObjectsForReactImage(imgSrcsArray);

        content = {
            layout: layout
        };  
    } else {
        content = {

        };
    }

    let panelId = "pageSizePanel-" + id;
    
    let resDict = {
        content: content,
        imageSrcs: imageSrcs,
        imgSrcsArray: imgSrcsArray,
        arrayOfObjectsForReactImage: arrayOfObjectsForReactImage,
        panelId: panelId
    }

    return resDict;
}

const getLayoutForPage = (id, imageSrcs, handleImageClick, text, backgrounds) => {
    let layoutForPage;

    switch(id) {
        case "page1":
            layoutForPage = getLayoutPage1(imageSrcs, backgrounds);
        break;
        case "page2":
            layoutForPage = getLayoutPage2(text, backgrounds, imageSrcs);
        break;
        case "page4":
            layoutForPage = getLayoutPage4(imageSrcs, handleImageClick, text);
        break;
        case "page5":
            layoutForPage = getLayoutPage5(imageSrcs, handleImageClick, text);
        break;
        case "page6":
            layoutForPage = getLayoutPage6(imageSrcs, handleImageClick, text);
        break;
        case "page7":
            layoutForPage = getLayoutPage7(imageSrcs, handleImageClick, text);
        break;
        case "page9":
            layoutForPage = getLayoutPage9(imageSrcs, handleImageClick, text);
        break;
        case "page10":
            layoutForPage = getLayoutPage10(imageSrcs, handleImageClick, text);
        break;
        case "page11":
            layoutForPage = getLayoutPage11(imageSrcs, handleImageClick, text);
        break;
        case "page13":
            layoutForPage = getLayoutPage13(imageSrcs, handleImageClick, text);
        break;
        case "page14":
            layoutForPage = getLayoutPage14(imageSrcs, handleImageClick, text);
        break;
        case "page15":
            layoutForPage = getLayoutPage15(imageSrcs, handleImageClick, text);
        break;
        case "page16":
            layoutForPage = getLayoutPage16(imageSrcs, handleImageClick, text);
        break;
        case "page17": 
            layoutForPage = getLayoutPage17(imageSrcs, handleImageClick, text);
        break;
        case "page18":  
            layoutForPage = getLayoutPage18(imageSrcs, handleImageClick, text);
        break;
        case "page19":  
            layoutForPage = getLayoutPage19(imageSrcs, backgrounds);
        break;
        default:
    }

    return layoutForPage;
}

const getImageForPage1 = (logo) => {
    return (
        <img src={logo} alt="main logo" id="sbLogo1"/>
    );
}

const getLayoutPage1 = (imageSrcs, backgrounds) => {
    
    let imageForPage1;

    if(imageSrcs.sbLogo1.length === 0) {
        imageForPage1 = null;
    } else {
        imageForPage1 = getImageForPage1(imageSrcs.sbLogo1);
    }

    let style = {  
        backgroundImage: "url(" + backgrounds.page1Background + ")",
        minHeight: "100vh",
        height: "fit-content"
    };

    let placeholderImage = getPlaceholderImage();
    
    return (
        <LazyLoad 
        height={lazyLoadHeight}
        offset={lazyload}
        resize={resize}
        once={onceSetting}
        placeholder={placeholderImage}>
            <div className="pageSizePanelLeftRightContainer" id="pageSizePanelLeftRightContainerPage1" style={style}>
                <div id="sbLogo1ContainerRow">
                    <div id="sbLogo1Container">
                        {imageForPage1}
                    </div>
                </div>
            </div>
        </LazyLoad>
    );
}

const getLayoutPage2 = (text, backgrounds, imageSrcs) => {

    let tinyImages = {
        tinyImage1: getPlaceholderImage(imageSrcs.tiny.image1),
        tinyImage2: getPlaceholderImage(imageSrcs.tiny.image2)
    }

    let style = {
        backgroundImage: "url(" + backgrounds.page2Background + ")",
        minHeight: "100vh",
        height: "fit-content"
    }

    return (
        <div className="pageSizePanelLeftRightContainer" id="pageSizePanelLeftRightContainerPage2" style={style}>
            <div id="logoPage2Box">
                <div id="logoPage2BoxInner">
                    <div id="aboutMeContainerLeft">
                        <div id="aboutMeFontContainer">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage1}>
                                <img src={imageSrcs.smaller.image1} alt={text.imgAlt1} id="aboutMeFont"/>
                            </LazyLoad>
                        </div>
                        <div id="aboutMeImageContainer">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage2}>
                                <img src={imageSrcs.smaller.image2} alt={text.imgAlt2} id="aboutMeImage"/>
                            </LazyLoad>
                        </div>
                    </div>
                    <div id="aboutMeContainerRight">
                        <p>{text.p1}</p>
                        <p>{text.p2}</p>
                        <p>{text.p3}</p>
                        <p>{text.p4}</p>
                        <p><strong>{text.p5}</strong></p>
                        <p>{text.p6}</p>
                        <p>{text.p7}</p>
                        <p>{text.p8}</p>
                        <p>{text.p9}</p>
                        <p>{text.p10}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
/*
const getLayoutPage2 = (text, backgrounds, imageSrcs) => {

    let tinyImages = {
        tinyImage1: getPlaceholderImage(imageSrcs.tiny.image1),
        tinyImage2: getPlaceholderImage(imageSrcs.tiny.image2)
    }

    let style = {
        backgroundImage: "url(" + backgrounds.page2Background + ")",
        minHeight: "100vh",
        height: "fit-content"
    }

    return (
        <div className="pageSizePanelLeftRightContainer" id="pageSizePanelLeftRightContainerPage2" style={style}>
            <div id="logoPage2Box">
                <div id="logoPage2BoxInner">
                    <div id="aboutMeContainerLeft">
                        <h1>{text.h1}</h1>
                        <div id="aboutMeImageContainer">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage2}>
                                <img src={imageSrcs.smaller.image2} alt={text.imgAlt2} id="aboutMeImage"/>
                            </LazyLoad>
                        </div>
                    </div>
                    <div id="aboutMeContainerRight">
                        <p>{text.p1}</p>
                        <p>{text.p2}</p>
                        <p>{text.p3}</p>
                        <p>{text.p4}</p>
                        <p><strong>{text.p5}</strong></p>
                        <p>{text.p6}</p>
                        <p>{text.p7}</p>
                        <p>{text.p8}</p>
                        <p>{text.p9}</p>
                        <p>{text.p10}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
*/
const getLayoutPage4Wide = (tinyImages, imageSrcs, handleImageClick, text) => {

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft">
                <div id="pageSizePanelLeftClickToEnlargeContainer">
                    <button className="pageSizePanelClickToEnlarge" id="pageSizePanelLeftClickToEnlarge">click images to enlarge</button>
                </div>
                <div id="action-force-SpecialForces20-21-container">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage1}>
                         <img src={imageSrcs.fullSize.image1} alt={text.imgAlt1} id="action-force-SpecialForces20-21-slide-1" onClick={handleImageClick}/>
                        </LazyLoad>
                </div>
            </div>
            <div className="pageSizePanelRight">
                <div id="pageSizePanelRightImageGrid">   
                    <div id="pageSizePanelRightImageGridTopRow">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage2}>
                        <img src={imageSrcs.medium.image2} alt={text.imgAlt2} id="action-force-special-forces-slide-2" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage3}>
                            <img src={imageSrcs.medium.image3} alt={text.imgAlt3} id="action-force-police-units-slide-3" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage4}>
                            <img src={imageSrcs.medium.image4} alt={text.imgAlt4} id="action-force-armed-services-slide-4" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage5}>
                            <img src={imageSrcs.medium.image5} alt={text.imgAlt5} id="action-force-border-defence-slide-5" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="pageSizePanelRightImageGridBottomRow">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage6}>
                            <img src={imageSrcs.medium.image6} alt={text.imgAlt6} id="action-force-air-force-slide-6" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage7}>
                            <img src={imageSrcs.medium.image7} alt={text.imgAlt7} id="action-force-army-slide-7" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage8}>
                            <img src={imageSrcs.medium.image8} alt={text.imgAlt8} id="action-force-special-operations-slide-8" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage9}>
                            <img src={imageSrcs.medium.image9} alt={text.imgAlt9} id="action-force-navy-slide-9" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
                <div id="actionForceTextContainer">
                    <div id="pageSizePanelRightTextBoxTop">
                        <p id="action-force-description-top"><strong>{text.strongTitle}</strong>{text.p1}</p>
                    </div>
                    <div id="pageSizePanelRightTextBoxBottom">
                        <p id="action-force-description-bottom">{text.p2}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage4Narrow = (tinyImages, imageSrcs, handleImageClick, text) => {

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft">
                <div id="action-force-SpecialForces20-21-container">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage1}>
                         <img src={imageSrcs.medium.image1} alt={text.imgAlt1} id="action-force-SpecialForces20-21-slide-1" onClick={handleImageClick}/>
                        </LazyLoad>
                </div>
            </div>
            <div id="actionForceTextContainer">
                <div id="pageSizePanelRightTextBoxTop">
                    <p id="action-force-description-top"><strong>{text.strongTitle}</strong>{text.p1}</p>
                </div>
                <div id="pageSizePanelRightTextBoxBottom">
                    <p id="action-force-description-bottom">{text.p2}</p>
                </div>
            </div>
            <div className="pageSizePanelRight">
                <div id="pageSizePanelRightImageGrid">
                    <div id="pageSizePanelRightImageGridTopRow">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage2}>
                        <img src={imageSrcs.medium.image2} alt={text.imgAlt2} id="action-force-special-forces-slide-2" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage3}>
                            <img src={imageSrcs.medium.image3} alt={text.imgAlt3} id="action-force-police-units-slide-3" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage4}>
                            <img src={imageSrcs.medium.image4} alt={text.imgAlt4} id="action-force-armed-services-slide-4" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage5}>
                            <img src={imageSrcs.medium.image5} alt={text.imgAlt5} id="action-force-border-defence-slide-5" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="pageSizePanelRightImageGridBottomRow">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage6}>
                            <img src={imageSrcs.medium.image6} alt={text.imgAlt6} id="action-force-air-force-slide-6" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage7}>
                            <img src={imageSrcs.medium.image7} alt={text.imgAlt7} id="action-force-army-slide-7" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage8}>
                            <img src={imageSrcs.medium.image8} alt={text.imgAlt8} id="action-force-special-operations-slide-8" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage9}>
                            <img src={imageSrcs.medium.image9} alt={text.imgAlt9} id="action-force-navy-slide-9" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage4 = (imageSrcs, handleImageClick, text) => {

    let tinyImages = {
        tinyImage1: getPlaceholderImage(imageSrcs.tiny.image1),
        tinyImage2: getPlaceholderImage(imageSrcs.tiny.image2),
        tinyImage3: getPlaceholderImage(imageSrcs.tiny.image3),

        tinyImage4: getPlaceholderImage(imageSrcs.tiny.image4),
        tinyImage5: getPlaceholderImage(imageSrcs.tiny.image5),
        tinyImage6: getPlaceholderImage(imageSrcs.tiny.image6),

        tinyImage7: getPlaceholderImage(imageSrcs.tiny.image7),
        tinyImage8: getPlaceholderImage(imageSrcs.tiny.image8),
        tinyImage9: getPlaceholderImage(imageSrcs.tiny.image9),
    }

    let layoutPage4;

    if(window.screen.width > 414) {
        layoutPage4 = getLayoutPage4Wide(tinyImages, imageSrcs, handleImageClick, text);
    } else {
        layoutPage4 = getLayoutPage4Narrow(tinyImages, imageSrcs, handleImageClick, text);
    }

    return layoutPage4;
}

const getLayoutPage5 = (imageSrcs, handleImageClick, text) => {
    let tinyImage1 = getPlaceholderImage(imageSrcs.tiny.image1);
    let tinyImage2 = getPlaceholderImage(imageSrcs.tiny.image2);
    let tinyImage3 = getPlaceholderImage(imageSrcs.tiny.image3);
    let tinyImage4 = getPlaceholderImage(imageSrcs.tiny.image4);
    let tinyImage5 = getPlaceholderImage(imageSrcs.tiny.image5);
    let tinyImage6 = getPlaceholderImage(imageSrcs.tiny.image6);
    let tinyImage7 = getPlaceholderImage(imageSrcs.tiny.image7);
    let tinyImage8 = getPlaceholderImage(imageSrcs.tiny.image8);
    
    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftPage5">
                <div className="whatHappensCoverContainer" id="whatHappensCoverContainerLeft">
                    <div id="whatHappensCoverContainerLeftLeft">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImage1}>
                                <img src={imageSrcs.medium.image1} alt={text.imgAlt1} id="uEOzoneCover-slide-1" onClick={handleImageClick}/>
                            </LazyLoad>
                    </div>
                    <div id="whatHappensCoverContainerLeftRight">
                        <div id="whatHappensCoverContainerLeftRightTop">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImage2}>
                                <img src={imageSrcs.smaller.image2} alt={text.imgAlt2} id="uEOceansCover-slide-2" onClick={handleImageClick}/>
                            </LazyLoad>
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImage3}>
                                <img src={imageSrcs.smaller.image3} alt={text.imgAlt3} id="uEIceCapcover-slide-3" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="whatHappensCoverContainerLeftRightBottom">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImage6}>
                                <img src={imageSrcs.smaller.image6} alt={text.imgAlt6} id="uERainforestsCover-slide-6" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
                <div id="pageSizePanelRightTextBoxTop">
                    <p id="unstableEarthDescription"><strong>{text.strongTitle}</strong>{text.p1}</p>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightPage5">
                <div className="whatHappensCoverContainer" id="whatHappensCoverContainerRight">
                    <div id="whatHappensCoverContainerRightTopRow">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage4}>
                            <img src={imageSrcs.medium.image4} alt={text.imgAlt4} id="ozone45-slide-4" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage5}>
                            <img src={imageSrcs.medium.image5} alt={text.imgAlt5} id="oceans2425-slide-5" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="whatHappensCoverContainerRightBottomRow">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage7}>
                            <img src={imageSrcs.medium.image7} alt={text.imgAlt7} id="iceCaps2223-slide-7" onClick={handleImageClick}/>
                        </LazyLoad>
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage8}>
                            <img src={imageSrcs.medium.image8} alt={text.imgAlt8} id="iceCaps2425-slide-8" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage6Wide = (tinyImages, imageSrcs, handleImageClick, text) => {

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftpage6">
                <div id="pageSizePanelLeftpage6TopLeftImagesContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImages.tinyImage1}>
                        <img src={imageSrcs.medium.image1} alt={text.imgAlt1} id="csPartyFood17Image-slide-1" onClick={handleImageClick}/>
                    </LazyLoad>
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImages.tinyImage2}>
                        <img src={imageSrcs.medium.image2} alt={text.imgAlt2} id="csPartyFood3233Image-slide-2" onClick={handleImageClick}/>
                    </LazyLoad>
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImages.tinyImage4}>
                        <img src={imageSrcs.medium.image4} alt={text.imgAlt4} id="csHealthySnacks3637Image-slide-4" onClick={handleImageClick}/>
                    </LazyLoad>
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImages.tinyImage5}>
                        <img src={imageSrcs.medium.image5} alt={text.imgAlt5} id="csClassics4041Image-slide-5" onClick={handleImageClick}/>
                    </LazyLoad>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightpage6">
                <div id="csPartyFood3839ImageContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImages.tinyImage3}>
                        <img src={imageSrcs.fullSize.image3} alt={text.imgAlt3} id="csPartyFood3839Image-slide-3" onClick={handleImageClick}/>
                    </LazyLoad>
                    <div id="pageSizePanelLeftpage6BottomLeftImagesContainer">
                        <div id="pageSizePanelLeftpage6BottomLeftImagesContainerLeft">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage6}>
                                <img src={imageSrcs.medium.image6} alt={text.imgAlt6} id="csDrinksAndDeserts1213Image-slide-6" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="pageSizePanelLeftpage6BottomLeftImagesContainerRight">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage7}>
                                <img src={imageSrcs.smaller.image7} alt={text.imgAlt7} id="classicCoverImage-slide-7" onClick={handleImageClick}/>
                            </LazyLoad>
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage8}>
                                <img src={imageSrcs.smaller.image8} alt={text.imgAlt8} id="drinksCoverImage-slide-8" onClick={handleImageClick}/>
                            </LazyLoad>
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage9}>
                                <img src={imageSrcs.smaller.image9} alt={text.imgAlt9} id="healthyCoverImage-slide-9" onClick={handleImageClick}/>
                            </LazyLoad>
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage10}>
                                <img src={imageSrcs.smaller.image10} alt={text.imgAlt10} id="partyCoverImage-slide-10" onClick={handleImageClick}/>
                            </LazyLoad>
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage11}>
                                <img src={imageSrcs.smaller.image11} alt={text.imgAlt11} id="pastaCoverImage-slide-11" onClick={handleImageClick}/>
                            </LazyLoad>
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage12}>
                                <img src={imageSrcs.smaller.image12} alt={text.imgAlt12} id="veggieCoverImage-slide-12" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="pageSizePanelPage6TextBox">
                            <p id="cookingSkillsDescription"><strong>{text.strongTitle}</strong></p>
                            <p id="cookingSkillsDescription">{text.p1}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
} 

const getLayoutPage6Narrow = (tinyImages, imageSrcs, handleImageClick, text) => {
    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftpage6">
                <div id="pageSizePanelLeftpage6TopLeftImagesContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImages.tinyImage1}>
                        <img src={imageSrcs.smaller.image1} alt={text.imgAlt1} id="csPartyFood17Image-slide-1" onClick={handleImageClick}/>
                    </LazyLoad>
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImages.tinyImage2}>
                        <img src={imageSrcs.smaller.image2} alt={text.imgAlt2} id="csPartyFood3233Image-slide-2" onClick={handleImageClick}/>
                    </LazyLoad>
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImages.tinyImage4}>
                        <img src={imageSrcs.smaller.image4} alt={text.imgAlt4} id="csHealthySnacks3637Image-slide-4" onClick={handleImageClick}/>
                    </LazyLoad>
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImages.tinyImage5}>
                        <img src={imageSrcs.smaller.image5} alt={text.imgAlt5} id="csClassics4041Image-slide-5" onClick={handleImageClick}/>
                    </LazyLoad>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightpage6">
                <div id="csPartyFood3839ImageContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImages.tinyImage3}>
                        <img src={imageSrcs.smaller.image3} alt={text.imgAlt3} id="csPartyFood3839Image-slide-3" onClick={handleImageClick}/>
                    </LazyLoad>
                    <div id="pageSizePanelPage6TextBox">
                        <p id="cookingSkillsDescription"><strong>{text.strongTitle}</strong></p>
                        <p id="cookingSkillsDescription">{text.p1}</p>
                    </div>
                    <div id="pageSizePanelLeftpage6BottomLeftImagesContainer">
                        <div id="pageSizePanelLeftpage6BottomLeftImagesContainerLeft">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage6}>
                                <img src={imageSrcs.smaller.image6} alt={text.imgAlt6} id="csDrinksAndDeserts1213Image-slide-6" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="pageSizePanelLeftpage6BottomLeftImagesContainerRight">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage7}>
                                <img src={imageSrcs.smaller.image7} alt={text.imgAlt7} id="classicCoverImage-slide-7" onClick={handleImageClick}/>
                            </LazyLoad>
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage8}>
                                <img src={imageSrcs.smaller.image8} alt={text.imgAlt8} id="drinksCoverImage-slide-8" onClick={handleImageClick}/>
                            </LazyLoad>
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage9}>
                                <img src={imageSrcs.smaller.image9} alt={text.imgAlt9} id="healthyCoverImage-slide-9" onClick={handleImageClick}/>
                            </LazyLoad>
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage10}>
                                <img src={imageSrcs.smaller.image10} alt={text.imgAlt10} id="partyCoverImage-slide-10" onClick={handleImageClick}/>
                            </LazyLoad>
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage11}>
                                <img src={imageSrcs.smaller.image11} alt={text.imgAlt11} id="pastaCoverImage-slide-11" onClick={handleImageClick}/>
                            </LazyLoad>
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage12}>
                                <img src={imageSrcs.smaller.image12} alt={text.imgAlt12} id="veggieCoverImage-slide-12" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
} 

const getLayoutPage6 = (imageSrcs, handleImageClick, text) => {
    let tinyImages = {
        tinyImage1: getPlaceholderImage(imageSrcs.tiny.image1),
        tinyImage2: getPlaceholderImage(imageSrcs.tiny.image2),
        tinyImage3: getPlaceholderImage(imageSrcs.tiny.image3),
        tinyImage4: getPlaceholderImage(imageSrcs.tiny.image4),
        tinyImage5: getPlaceholderImage(imageSrcs.tiny.image5),
        tinyImage6: getPlaceholderImage(imageSrcs.tiny.image6),
        tinyImage7: getPlaceholderImage(imageSrcs.tiny.image7),
        tinyImage8: getPlaceholderImage(imageSrcs.tiny.image8),
        tinyImage9: getPlaceholderImage(imageSrcs.tiny.image9),
        tinyImage10: getPlaceholderImage(imageSrcs.tiny.image10),
        tinyImage11: getPlaceholderImage(imageSrcs.tiny.image11),
        tinyImage12: getPlaceholderImage(imageSrcs.tiny.image12),
    }

    let layoutPage6;

    if(window.screen.width > 414) {
        layoutPage6 = getLayoutPage6Wide(tinyImages, imageSrcs, handleImageClick, text);
    } else {
        layoutPage6 = getLayoutPage6Narrow(tinyImages, imageSrcs, handleImageClick, text);
    }

    return layoutPage6;
}

const getLayoutPage7Wide = (tinyImages, imageSrcs, handleImageClick, text) => {

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftPage7">
                <div id="pageSizePanelRightTextBoxTop">
                    <p id="darkPastDescription"><strong>{text.strongTitle}</strong>{text.p1}</p>
                </div>
                <div className="darkPastContainer" id="darkPastContainerLeft">
                    <div id="darkPastContainerLeftLeft">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage1}>
                            <img src={imageSrcs.medium.image1} alt={text.imgAlt1} id="vikingCover61-slide-1" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="darkPastContainerLeftRight">
                        <div id="darkPastContainerLeftRightTop">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage2}>
                                <img src={imageSrcs.smaller.image2} alt={text.imgAlt2} id="ghostsCover63-slide-2" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="darkPastContainerLeftRightBottom">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage5}>
                                <img src={imageSrcs.smaller.image5} alt={text.imgAlt5} id="aliensCover6-slide-5" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightPage7">
                <div className="darkPastContainer" id="darkPastContainerRight">
                    <div id="darkPastContainerRightLeft">
                        <div id="darkPastContainerLeftRightTop">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage3}>
                                <img src={imageSrcs.smaller.image3} alt={text.imgAlt3} id="vikingCover66-slide-3" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="darkPastContainerLeftRightBottom">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage6}>
                                <img src={imageSrcs.smaller.image6} alt={text.imgAlt6} id="witchesCover64-slide-6" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                    <div id="darkPastContainerRightRight">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage4}>
                            <img src={imageSrcs.medium.image4} alt={text.imgAlt4} id="vikingsSpread623-slide-4" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage7Narrow = (tinyImages, imageSrcs, handleImageClick, text) => {

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftPage7">
                <div className="darkPastContainer" id="darkPastContainerLeft">
                    <div id="darkPastContainerLeftLeft">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage1}>
                            <img src={imageSrcs.smaller.image1} alt={text.imgAlt1} id="vikingCover61-slide-1" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="darkPastContainerLeftRight">
                        <div id="darkPastContainerLeftRightTop">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage2}>
                                <img src={imageSrcs.smaller.image2} alt={text.imgAlt2} id="ghostsCover63-slide-2" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="darkPastContainerLeftRightBottom">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage5}>
                                <img src={imageSrcs.smaller.image5} alt={text.imgAlt5} id="aliensCover6-slide-5" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
            <div id="pageSizePanelRightTextBoxTop">
                <p id="darkPastDescription"><strong>{text.strongTitle}</strong>{text.p1}</p>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightPage7">
                <div className="darkPastContainer" id="darkPastContainerRight">
                    <div id="darkPastContainerRightLeft">
                        <div id="darkPastContainerLeftRightTop">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage3}>
                                <img src={imageSrcs.smaller.image3} alt={text.imgAlt3} id="vikingCover66-slide-3" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="darkPastContainerLeftRightBottom">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage6}>
                                <img src={imageSrcs.smaller.image6} alt={text.imgAlt6} id="witchesCover64-slide-6" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                    <div id="darkPastContainerRightRight">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage4}>
                            <img src={imageSrcs.smaller.image4} alt={text.imgAlt4} id="vikingsSpread623-slide-4" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage7 = (imageSrcs, handleImageClick, text) => {

    let tinyImages = {
        tinyImage1: getPlaceholderImage(imageSrcs.tiny.image1),
        tinyImage2: getPlaceholderImage(imageSrcs.tiny.image2),
        tinyImage3: getPlaceholderImage(imageSrcs.tiny.image3),
        tinyImage4: getPlaceholderImage(imageSrcs.tiny.image4),
        tinyImage5: getPlaceholderImage(imageSrcs.tiny.image5),
        tinyImage6: getPlaceholderImage(imageSrcs.tiny.image6)
    }

    let layoutPage7;

    if(window.screen.width > 414) {
        layoutPage7 = getLayoutPage7Wide(tinyImages, imageSrcs, handleImageClick, text);
    } else {
        layoutPage7 = getLayoutPage7Narrow(tinyImages, imageSrcs, handleImageClick, text);
    }

    return layoutPage7;
}

const getLayoutPage9 = (imageSrcs, handleImageClick, text) => {

    let tinyImage1 = getPlaceholderImage(imageSrcs.tiny.image1);
    let tinyImage2 = getPlaceholderImage(imageSrcs.tiny.image2);
    let tinyImage3 = getPlaceholderImage(imageSrcs.tiny.image3);
    let tinyImage4 = getPlaceholderImage(imageSrcs.tiny.image4);

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftPage9">
                <div id="pageSizePanelLeftPage9ImagesContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage1}>
                        <img src={imageSrcs.medium.image1} alt={text.imgAlt1} id="calligraphy100101-slide-1" onClick={handleImageClick}/>
                    </LazyLoad>
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage2}>
                        <img src={imageSrcs.medium.image2} alt={text.imgAlt2} id="calligraphy6263-slide-2" onClick={handleImageClick}/>
                    </LazyLoad>
                    <div id="pageSizePanelPage9TextBox">
                        <p id="calligraphyDescription"><strong>{text.strongTitle}</strong>{text.p1}</p>
                    </div>
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage4}>
                        <img src={imageSrcs.medium.image4} alt={text.imgAlt4} id="calligraphy2021-slide-4" onClick={handleImageClick}/>
                    </LazyLoad>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightPage9">
                <div id="calligraphy4041Container">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage3}>
                        <img src={imageSrcs.fullSize.image3} alt={text.imgAlt3} id="calligraphy4041-slide-3" onClick={handleImageClick}/>
                    </LazyLoad>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage10 = (imageSrcs, handleImageClick, text) => {

    let tinyImage1 = getPlaceholderImage(imageSrcs.tiny.image1);
    let tinyImage2 = getPlaceholderImage(imageSrcs.tiny.image2);
    let tinyImage3 = getPlaceholderImage(imageSrcs.tiny.image3);

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftPage10">
                <div id="hygge2627Container">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage1}>
                        <img src={imageSrcs.fullSize.image1} alt={text.imgAlt1} id="hygge2627-slide-1" onClick={handleImageClick}/>
                    </LazyLoad>
                </div>
                <div id="pageSizePanelPage10TextBox">
                    <p id="hyggeDescription"><strong>{text.strongTitle}</strong></p>
                    <p id="hyggeDescription">{text.p1}</p>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightPage10">
                <div id="hygge5253hygge1011Container">
                    <div id="hygge5253hygge1011ContainerTop">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage2}>
                        <img src={imageSrcs.medium.image2} alt={text.imgAlt2} id="hygge5253-slide-2" onClick={handleImageClick}/>
                    </LazyLoad>
                    </div>
                    <div id="hygge5253hygge1011ContainerBottom">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage3}>
                        <img src={imageSrcs.medium.image3} alt={text.imgAlt3} id="hygge1011-slide-3" onClick={handleImageClick}/>
                    </LazyLoad>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage11 = (imageSrcs, handleImageClick, text) => {

    let tinyImage1 = getPlaceholderImage(imageSrcs.tiny.image1);
    let tinyImage2 = getPlaceholderImage(imageSrcs.tiny.image2);
    let tinyImage3 = getPlaceholderImage(imageSrcs.tiny.image3);

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftPage11">
                <div id="silburyHillBook188FinalImageContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage1}>
                        <img src={imageSrcs.medium.image1} alt={text.imgAlt1} id="silburyHillBook188FinalImage-slide-1" onClick={handleImageClick}/>
                    </LazyLoad>
                </div>
                <div id="pageSizePanelPage11TextBox">
                    <p id="silburyHillDescription"><strong>{text.strongTitle}</strong>{text.p1}</p>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightPage11">
                <div id="silburyHillBook88200FinalImageContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage2}>
                        <img src={imageSrcs.medium.image2} alt={text.imgAlt2} id="silburyHillBook88200FinalImage-slide-2" onClick={handleImageClick}/>
                    </LazyLoad>
                </div>
                <div id="silburyHillCoverFinalImageContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage3}>
                        <img src={imageSrcs.medium.image3} alt={text.imgAlt3} id="silburyHillCoverFinalImage-slide-3" onClick={handleImageClick}/>
                    </LazyLoad>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage13 = (imageSrcs, handleImageClick, text) => {

    let tinyImage1 = getPlaceholderImage(imageSrcs.tiny.image1);
    let tinyImage2 = getPlaceholderImage(imageSrcs.tiny.image2);
    let tinyImage3 = getPlaceholderImage(imageSrcs.tiny.image3);
    let tinyImage4 = getPlaceholderImage(imageSrcs.tiny.image4);
    let tinyImage5 = getPlaceholderImage(imageSrcs.tiny.image5);
    let tinyImage6 = getPlaceholderImage(imageSrcs.tiny.image6);
    let tinyImage7 = getPlaceholderImage(imageSrcs.tiny.image7);

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftPage13">
                <div id="pageSizePanelLeftPage13ImageAndTextContainer">
                    <div id="seriesCoverDesigns2ImageContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage1}>
                        <img src={imageSrcs.medium.image1} alt={text.imgAlt1} id="seriesCoverDesigns2Image-slide-1" onClick={handleImageClick}/>
                    </LazyLoad>
                    </div>
                    <div id="pageSizePanelPage13TextBox">
                        <p id="silburyHillDescription"><strong>{text.strongTitle}</strong>{text.p1}</p>
                    </div>
                </div>
                <div id="seriesCoverDesigns4ImageContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage2}>
                        <img src={imageSrcs.smaller.image2} alt={text.imgAlt2} id="seriesCoverDesigns3Image-slide-2" onClick={handleImageClick}/>
                    </LazyLoad>
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage3}>
                        <img src={imageSrcs.smaller.image3} alt={text.imgAlt3} id="seriesCoverDesigns1Image-slide-3" onClick={handleImageClick}/>
                    </LazyLoad>
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage5}>
                        <img src={imageSrcs.smaller.image5} alt={text.imgAlt5} id="seriesCoverDesigns6Image-slide-5" onClick={handleImageClick}/>
                    </LazyLoad>
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage6}>
                        <img src={imageSrcs.smaller.image6} alt={text.imgAlt6} id="seriesCoverDesigns4aImage-slide-6" onClick={handleImageClick}/>
                    </LazyLoad>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightPage13">
                <div id="navigatorGuidesSampleSpread1213ImageContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage4}>
                        <img src={imageSrcs.fullSize.image4} alt={text.imgAlt4} id="navigatorGuidesSampleSpread1213Image-slide-4" onClick={handleImageClick}/>
                    </LazyLoad>
                </div>
                <div id="navigatorGuidesSampleSpread1415ImageContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage7}>
                        <img src={imageSrcs.fullSize.image7} alt={text.imgAlt7} id="navigatorGuidesSampleSpread1415Image-slide-7" onClick={handleImageClick}/>
                    </LazyLoad>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage14 = (imageSrcs, handleImageClick, text) => {

    let tinyImage1 = getPlaceholderImage(imageSrcs.tiny.image1);
    let tinyImage2 = getPlaceholderImage(imageSrcs.tiny.image2);
    let tinyImage3 = getPlaceholderImage(imageSrcs.tiny.image3);
    let tinyImage4 = getPlaceholderImage(imageSrcs.tiny.image4);
    let tinyImage5 = getPlaceholderImage(imageSrcs.tiny.image5);

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftPage14">
                <div id="pageSizePanelLeftPage14ImageContainer">
                    <div id="pageSizePanelLeftPage14ImageContainerLeft">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage1}>
                        <img src={imageSrcs.medium.image1} alt={text.imgAlt1} id="wells1415Image-slide-1" onClick={handleImageClick}/>
                    </LazyLoad>
                    </div>
                    <div id="pageSizePanelLeftPage14ImageContainerRight">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage2}>
                            <img src={imageSrcs.medium.image2} alt={text.imgAlt2} id="wells1011Image-slide-2" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
                <div id="pageSizePanelPage14TextBox">
                    <p id="wellsCathedralDescription"><strong>{text.strongTitle}</strong>{text.p1}</p>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightPage14">
                <div id="pageSizePanelRightPage14ImageContainer">
                    <div id="pageSizePanelRightPage14ImageContainerLeft">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage3}>
                        <img src={imageSrcs.smaller.image3} alt={text.imgAlt3} id="wellsCoverImage-slide-3" onClick={handleImageClick}/>
                    </LazyLoad>
                    </div>
                    <div id="pageSizePanelRightPage14ImageContainerCentre">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage4}>
                        <img src={imageSrcs.medium.image4} alt={text.imgAlt4} id="wellsPage12Image-slide-4" onClick={handleImageClick}/>
                    </LazyLoad>
                    </div>
                    <div id="pageSizePanelRightPage14ImageContainerRight">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage5}>
                        <img src={imageSrcs.medium.image5} alt={text.imgAlt5} id="page30Image-slide-5" onClick={handleImageClick}/>
                    </LazyLoad>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

const getLayoutPage15 = (imageSrcs, handleImageClick, text) => {

    let tinyImage1 = getPlaceholderImage(imageSrcs.tiny.image1);
    let tinyImage2 = getPlaceholderImage(imageSrcs.tiny.image2);
    let tinyImage3 = getPlaceholderImage(imageSrcs.tiny.image3);
    let tinyImage4 = getPlaceholderImage(imageSrcs.tiny.image4);

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftPage15">
                <div id="pageSizePanelLeftPage15TopRow">
                    <div id="coverV6ImageContainer">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage1}>
                            <img src={imageSrcs.medium.image1} alt={text.imgAlt1} id="coverV6Image-slide-1" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="hurstCastle23ImageContainer">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage2}>
                            <img src={imageSrcs.medium.image2} alt={text.imgAlt2} id="hurstCastle23Image-slide-2" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
                <div id="pageSizePanelLeftPage15BottomRow">
                    <div id="pageSizePanelPage15TextBox">
                        <p className="hurstCastleDescription" id="hurstCastleDescriptionTop"><strong>{text.strongTitle}</strong></p>
                        <p className="hurstCastleDescription" id="hurstCastleDescriptionBottom"><strong>{text.strongTitle2}</strong>{text.p1}</p>
                        <p id="hurstCastleDescriptionQuote">{text.p2}</p>
                    </div>
                    <div id="hurstCastle1415ImageContainer">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage4}>
                            <img src={imageSrcs.medium.image4} alt={text.imgAlt4} id="hurstCastle1415Image-slide-4" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightPage15">
                <div id="hurstCastle1617ImageContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImage3}>
                        <img src={imageSrcs.fullSize.image3} alt={text.imgAlt3} id="hurstCastle1617Image-slide-3" onClick={handleImageClick}/>
                    </LazyLoad>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage16Wide = (tinyImages, imageSrcs, handleImageClick, text) => {

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftPage16">
                <div id="pageSizePanelPage16TextBox">
                    <p id="pageSizePanelPage16TextBoxTop"><strong>{text.strongTitle}</strong></p>
                    <p id="infoGraphicsDescriptionMid">{text.p1}</p>
                    <p id="infoGraphicsDescriptionBottom"><strong>{text.strongTitle2}</strong></p>
                </div>
                <div>
                    <div id="pageSizePanelLeftPage16ImageRowTop">
                        <div id="clovellyGuideBookRecipeBook1ImageContainer">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage1}>
                                <img src={imageSrcs.smaller.image1} alt={text.imgAlt1} id="clovellyGuideBookRecipeBook1Image-slide-1" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="clovellyGuideBookRecipeBook2ImageContainer">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage2}>
                                <img src={imageSrcs.medium.image2} alt={text.imgAlt2} id="clovellyGuideBookRecipeBook2Image-slide-2" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                    <div id="pageSizePanelLeftPage16ImageRowBottom">
                        <div id="clovellyGuideBookRecipeBook4ImageContainer">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage4}>
                                <img src={imageSrcs.smaller.image4} alt={text.imgAlt4} id="clovellyGuideBookRecipeBook4Image-slide-4" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="clovellyGuideBookRecipeBook5ImageContainer">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage5}>
                                <img src={imageSrcs.medium.image5} alt={text.imgAlt5} id="clovellyGuideBookRecipeBook5Image-slide-5" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightPage16">
                <div id="clovellyGuideBookRecipeBook3ImageContainer">
                    <LazyLoad 
                    height={lazyLoadHeight}
                    offset={lazyload}
                    resize={resize}
                    throttle={throttle}
                    once={onceSetting}
                    placeholder={tinyImages.tinyImage3}>
                        <img src={imageSrcs.fullSize.image3} alt={text.imgAlt3} id="clovellyGuideBookRecipeBook3Image-slide-3" onClick={handleImageClick}/>
                    </LazyLoad>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage16Narrow = (tinyImages, imageSrcs, handleImageClick, text) => {

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftPage16">
                <div>
                    <div id="pageSizePanelLeftPage16ImageRowTop">
                        <div id="clovellyGuideBookRecipeBook1ImageContainer">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage1}>
                                <img src={imageSrcs.smaller.image1} alt={text.imgAlt1} id="clovellyGuideBookRecipeBook1Image-slide-1" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="clovellyGuideBookRecipeBook2ImageContainer">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage2}>
                                <img src={imageSrcs.smaller.image2} alt={text.imgAlt2} id="clovellyGuideBookRecipeBook2Image-slide-2" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                    <div id="pageSizePanelPage16TextBox">
                        <p id="pageSizePanelPage16TextBoxTop"><strong>{text.strongTitle}</strong></p>
                        <p id="infoGraphicsDescriptionMid">{text.p1}</p>
                    </div>
                    <div id="clovellyGuideBookRecipeBook3ImageContainer">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage3}>
                            <img src={imageSrcs.smaller.image3} alt={text.imgAlt3} id="clovellyGuideBookRecipeBook3Image-slide-3" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="pageSizePanelLeftPage16ImageRowBottom">
                        <div id="clovellyGuideBookRecipeBook4ImageContainer">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage4}>
                                <img src={imageSrcs.smaller.image4} alt={text.imgAlt4} id="clovellyGuideBookRecipeBook4Image-slide-4" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="clovellyGuideBookRecipeBook5ImageContainer">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage5}>
                                <img src={imageSrcs.smaller.image5} alt={text.imgAlt5} id="clovellyGuideBookRecipeBook5Image-slide-5" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
            <div id="pageSizePanelPage16TextBox">
                <p id="infoGraphicsDescriptionBottom"><strong>{text.strongTitle2}</strong></p>
            </div>
        </div>
    );
}

const getLayoutPage16 = (imageSrcs, handleImageClick, text) => {

    let tinyImages = {
        tinyImage1: getPlaceholderImage(imageSrcs.tiny.image1),
        tinyImage2: getPlaceholderImage(imageSrcs.tiny.image2),
        tinyImage3: getPlaceholderImage(imageSrcs.tiny.image3),
        tinyImage4: getPlaceholderImage(imageSrcs.tiny.image4),
        tinyImage5: getPlaceholderImage(imageSrcs.tiny.image5)
    }

    let layoutPage16;

    if(window.screen.width > 414) {
        layoutPage16 = getLayoutPage16Wide(tinyImages, imageSrcs, handleImageClick, text);
    } else {
        layoutPage16 = getLayoutPage16Narrow(tinyImages, imageSrcs, handleImageClick, text);
    }

    return layoutPage16;
}

const getLayoutPage17Wide = (tinyImages, imageSrcs, handleImageClick, text) => {

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftPage17">
                <div id="clovellyLeafletsNewsletterLeftTopContainer">
                    <div id="clovellyLeafletsNewsletterLeftTopContainerBothImages">
                        <div id="clovellyLeafletsNewsletterLeftTopContainerLeftImage">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage1}>
                                <img src={imageSrcs.medium.image1} alt={text.imgAlt1} id="clovellyLeafletsNewsletterImage1-slide-1" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="clovellyLeafletsNewsletterLeftTopContainerRightImage">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage2}>
                                <img src={imageSrcs.medium.image2} alt={text.imgAlt2} id="clovellyLeafletsNewsletterImage2-slide-2" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
                <div id="clovellyLeafletsNewsletterLeftBottomContainer">
                    <div id="clovellyLeafletsNewsletterLeftBottomContainerBothImages">
                        <div id="clovellyLeafletsNewsletterLeftBottomContainerLeft">
                            <div id="pageSizePanelPage17TextBox">
                                <p id="pageSizePanelPage17TextBoxTop"><strong>{text.strongTitle}</strong>{text.p1}</p>
                                <p id="clovellyVillageNewsletterTextMid"><strong>{text.strongTitle2}</strong></p>
                                <p id="clovellyVillageNewsletterTextBottom">{text.p2}</p>
                            </div>
                        </div>
                        <div id="clovellyLeafletsNewsletterLeftBottomContainerRight">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage5}>
                                <img src={imageSrcs.medium.image5} alt={text.imgAlt5} id="clovellyLeafletsNewsletterImage5-slide-5" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightPage17">
                <div id="clovellyLeafletsNewsletterRightTopContainer">
                    <div id="clovellyLeafletsNewsletterRightTopContainerBothImages">
                        <div id="clovellyLeafletsNewsletterRightTopContainerLeftImage">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage3}>
                                <img src={imageSrcs.medium.image3} alt={text.imgAlt3} id="clovellyLeafletsNewsletterImage3-slide-3" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="clovellyLeafletsNewsletterRightTopContainerRightImage">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage4}>
                                <img src={imageSrcs.smaller.image4} alt={text.imgAlt4} id="clovellyLeafletsNewsletterImage4-slide-4" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
                <div id="clovellyLeafletsNewsletterRightBottomContainer">
                    <div id="clovellyLeafletsNewsletterRightBottomContainerBothImages">
                        <div id="clovellyLeafletsNewsletterRightBottomContainerLeft">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage6}>
                                <img src={imageSrcs.medium.image6} alt={text.imgAlt6} id="clovellyLeafletsNewsletterImage6-slide-6" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="clovellyLeafletsNewsletterRightBottomContainerRight">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage7}>
                                <img src={imageSrcs.medium.image7} alt={text.imgAlt7} id="clovellyLeafletsNewsletterImage7-slide-7" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage17Narrow = (tinyImages, imageSrcs, handleImageClick, text) => {

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftPage17">
                <div id="clovellyLeafletsNewsletterLeftTopContainer">
                    <div id="clovellyLeafletsNewsletterLeftTopContainerBothImages">
                        <div id="clovellyLeafletsNewsletterLeftTopContainerLeftImage">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage1}>
                                <img src={imageSrcs.medium.image1} alt={text.imgAlt1} id="clovellyLeafletsNewsletterImage1-slide-1" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="pageSizePanelPage17TextBox">
                            <p id="pageSizePanelPage17TextBoxTop"><strong>{text.strongTitle}</strong>{text.p1}</p>
                        </div>
                        <div id="clovellyLeafletsNewsletterLeftTopContainerRightImage">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage2}>
                                <img src={imageSrcs.smaller.image2} alt={text.imgAlt2} id="clovellyLeafletsNewsletterImage2-slide-2" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="clovellyLeafletsNewsletterRightTopContainerNarrow">
                            <div id="clovellyLeafletsNewsletterRightTopContainerLeftImage">
                                <LazyLoad 
                                height={lazyLoadHeight}
                                offset={lazyload}
                                resize={resize}
                                throttle={throttle}
                                once={onceSetting}
                                placeholder={tinyImages.tinyImage3}>
                                    <img src={imageSrcs.smaller.image3} alt={text.imgAlt3} id="clovellyLeafletsNewsletterImage3-slide-3" onClick={handleImageClick}/>
                                </LazyLoad>
                            </div>
                            <div id="clovellyLeafletsNewsletterRightTopContainerRightImage">
                                <LazyLoad 
                                height={lazyLoadHeight}
                                offset={lazyload}
                                resize={resize}
                                throttle={throttle}
                                once={onceSetting}
                                placeholder={tinyImages.tinyImage4}>
                                    <img src={imageSrcs.smaller.image4} alt={text.imgAlt4} id="clovellyLeafletsNewsletterImage4-slide-4" onClick={handleImageClick}/>
                                </LazyLoad>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightPage17">
                <div id="clovellyLeafletsNewsletterRightBottomContainer">
                    <div id="clovellyLeafletsNewsletterRightBottomContainerBothImages">
                        <div id="clovellyLeafletsNewsletterRightBottomContainerLeft">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage6}>
                                <img src={imageSrcs.smaller.image6} alt={text.imgAlt6} id="clovellyLeafletsNewsletterImage6-slide-6" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="clovellyLeafletsNewsletterRightBottomContainerRight">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage7}>
                                <img src={imageSrcs.smaller.image7} alt={text.imgAlt7} id="clovellyLeafletsNewsletterImage7-slide-7" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                        <div id="pageSizePanelPage17TextBox">
                            <p id="clovellyVillageNewsletterTextMid"><strong>{text.strongTitle2}</strong></p>
                            <p id="clovellyVillageNewsletterTextBottom">{text.p2}</p>
                        </div>
                        <div id="clovellyLeafletsNewsletterLeftBottomContainerRight">
                            <LazyLoad 
                            height={lazyLoadHeight}
                            offset={lazyload}
                            resize={resize}
                            throttle={throttle}
                            once={onceSetting}
                            placeholder={tinyImages.tinyImage5}>
                                <img src={imageSrcs.medium.image5} alt={text.imgAlt5} id="clovellyLeafletsNewsletterImage5-slide-5" onClick={handleImageClick}/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage17 = (imageSrcs, handleImageClick, text) => {
    
    let tinyImages = {
        tinyImage1: getPlaceholderImage(imageSrcs.tiny.image1),
        tinyImage2: getPlaceholderImage(imageSrcs.tiny.image2),
        tinyImage3: getPlaceholderImage(imageSrcs.tiny.image3),
        tinyImage4: getPlaceholderImage(imageSrcs.tiny.image4),
        tinyImage5: getPlaceholderImage(imageSrcs.tiny.image5),
        tinyImage6: getPlaceholderImage(imageSrcs.tiny.image6),
        tinyImage7: getPlaceholderImage(imageSrcs.tiny.image7)
    }

    let layoutPage17;

    if(window.screen.width > 414) {
        layoutPage17 = getLayoutPage17Wide(tinyImages, imageSrcs, handleImageClick, text);
    } else {
        layoutPage17 = getLayoutPage17Narrow(tinyImages, imageSrcs, handleImageClick, text);
    }

    return layoutPage17;
}

const getLayoutPage18Wide = (tinyImages, imageSrcs, handleImageClick, text) => {

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftpage18">
                <div id="infoGraphicsImagesLeftContainer">
                    <div id="infoGraphicsImagesLeftContainerLeft">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage1}>
                            <img src={imageSrcs.fullSize.image1} alt={text.imgAlt1} id="infoGraphics1Image-slide-1" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="infoGraphicsImagesLeftContainerCentre">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage2}>
                            <img src={imageSrcs.fullSize.image2} alt={text.imgAlt2} id="infoGraphics2Image-slide-2" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="infoGraphicsImagesLeftContainerRight">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage3}>
                            <img src={imageSrcs.fullSize.image3} alt={text.imgAlt3} id="infoGraphics3Image-slide-3" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
                <div id="pageSizePanelPage18TextBox">
                    <p id="infoGraphicsDescription"><strong>{text.strongTitle}</strong></p>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightpage18">
                <div id="infoGraphicsImagesRightContainer">
                    <div id="infoGraphicsImagesRightContainerLeft">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage4}>
                            <img src={imageSrcs.fullSize.image4} alt={text.imgAlt4} id="infoGraphics4Image-slide-4" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="infoGraphicsImagesRightContainerCentre">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage5}>
                            <img src={imageSrcs.fullSize.image5} alt={text.imgAlt5} id="infoGraphics5Image-slide-5" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="infoGraphicsImagesRightContainerRight">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage6}>
                            <img src={imageSrcs.fullSize.image6} alt={text.imgAlt6} id="infoGraphics6Image-slide-6" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage18Narrow = (tinyImages, imageSrcs, handleImageClick, text) => {

    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftpage18">
                <div id="infoGraphicsImagesLeftContainer">
                    <div id="infoGraphicsImagesLeftContainerLeft">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage1}>
                            <img src={imageSrcs.smaller.image1} alt={text.imgAlt1} id="infoGraphics1Image-slide-1" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="infoGraphicsImagesLeftContainerCentre">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage2}>
                            <img src={imageSrcs.smaller.image2} alt={text.imgAlt2} id="infoGraphics2Image-slide-2" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
                <div id="pageSizePanelPage18TextBox">
                    <p id="infoGraphicsDescription"><strong>{text.strongTitle}</strong></p>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightpage18">
                <div id="infoGraphicsImagesRightContainer">
                    <div id="infoGraphicsImagesLeftContainerRight">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage3}>
                            <img src={imageSrcs.smaller.image3} alt={text.imgAlt3} id="infoGraphics3Image-slide-3" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="infoGraphicsImagesRightContainerLeft">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage4}>
                            <img src={imageSrcs.smaller.image4} alt={text.imgAlt4} id="infoGraphics4Image-slide-4" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightpage18">
                <div id="infoGraphicsImagesRightContainer">
                    <div id="infoGraphicsImagesRightContainerCentre">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage5}>
                            <img src={imageSrcs.smaller.image5} alt={text.imgAlt5} id="infoGraphics5Image-slide-5" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="infoGraphicsImagesRightContainerRight">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImages.tinyImage6}>
                            <img src={imageSrcs.smaller.image6} alt={text.imgAlt6} id="infoGraphics6Image-slide-6" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getLayoutPage18 = (imageSrcs, handleImageClick, text) => {

    let tinyImages = {
        tinyImage1: getPlaceholderImage(imageSrcs.tiny.image1),
        tinyImage2: getPlaceholderImage(imageSrcs.tiny.image2),
        tinyImage3: getPlaceholderImage(imageSrcs.tiny.image3),
        tinyImage4: getPlaceholderImage(imageSrcs.tiny.image4),
        tinyImage5: getPlaceholderImage(imageSrcs.tiny.image5),
        tinyImage6: getPlaceholderImage(imageSrcs.tiny.image6)
    }

    let layoutPage18;

    if(window.screen.width > 414) {
        layoutPage18 = getLayoutPage18Wide(tinyImages, imageSrcs, handleImageClick, text);
    } else {
        layoutPage18 = getLayoutPage18Narrow(tinyImages, imageSrcs, handleImageClick, text);
    }

    return layoutPage18;

    /*
    return (
        <div className="pageSizePanelLeftRightContainer">
            <div className="pageSizePanelLeft" id="pageSizePanelLeftpage18">
                <div id="infoGraphicsImagesLeftContainer">
                    <div id="infoGraphicsImagesLeftContainerLeft">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage1}>
                            <img src={imageSrcs.smaller.image1} alt="infoGraphics1Image" id="infoGraphics1Image-slide-1" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="infoGraphicsImagesLeftContainerCentre">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage2}>
                            <img src={imageSrcs.smaller.image2} alt="infoGraphics2Image" id="infoGraphics2Image-slide-2" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="infoGraphicsImagesLeftContainerRight">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage3}>
                            <img src={imageSrcs.smaller.image3} alt="infoGraphics3Image" id="infoGraphics3Image-slide-3" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
                <div id="pageSizePanelPage18TextBox">
                    <p id="infoGraphicsDescription"><strong>{text.strongTitle}</strong></p>
                </div>
            </div>
            <div className="pageSizePanelRight" id="pageSizePanelRightpage18">
                <div id="infoGraphicsImagesRightContainer">
                    <div id="infoGraphicsImagesRightContainerLeft">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage4}>
                            <img src={imageSrcs.smaller.image4} alt="infoGraphics4Image" id="infoGraphics4Image-slide-4" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="infoGraphicsImagesRightContainerCentre">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage5}>
                            <img src={imageSrcs.smaller.image5} alt="infoGraphics5Image" id="infoGraphics5Image-slide-5" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                    <div id="infoGraphicsImagesRightContainerRight">
                        <LazyLoad 
                        height={lazyLoadHeight}
                        offset={lazyload}
                        resize={resize}
                        throttle={throttle}
                        once={onceSetting}
                        placeholder={tinyImage6}>
                            <img src={imageSrcs.smaller.image6} alt="infoGraphics6Image" id="infoGraphics6Image-slide-6" onClick={handleImageClick}/>
                        </LazyLoad>
                    </div>
                </div>
            </div>
        </div>
    );
    */
}

const getLayoutPage19 = (imageSrcs, backgrounds) => {

    let style = {
        backgroundImage: "url(" + backgrounds.page19Background + ")",
        minHeight: "100vh",
        height: "fit-content"
    }

    return (
        <div className="pageSizePanelLeftRightContainer" id="pageSizePanelLeftRightContainerPage19" style={style}>
            <div id="sbLogo2Container">
                <div id="sbLogo2ContainerWithLink">
                    <a href="mailto: &#115;&#105;&#109;&#111;&#110;&#098;&#111;&#114;&#114;&#111;&#117;&#103;&#104;&#064;&#109;&#101;&#046;&#099;&#111;&#109;">
                        <img src={imageSrcs.sbLogo2} alt="main logo" id="sbLogo2"/>
                    </a>
                </div>
            </div>
            <div id="creditTextContainer">
                <p id="creditText">Site designed by <a href="mailto: &#097;&#104;&#097;&#108;&#107;&#101;&#114;&#115;&#116;&#111;&#110;&#046;&#119;&#101;&#098;&#100;&#101;&#115;&#105;&#103;&#110;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;" id="creditEmailLink"><strong>Andrew Halkerston</strong></a></p>
            </div>
        </div>
    );
/*
    return (
        <div className="pageSizePanelLeftRightContainer" id="pageSizePanelLeftRightContainerPage19" style={style}>
            <div id="sbLogo2Container">
                <div id="sbLogo2ContainerWithLink">
                    <a href="mailto: simonborrough@me.com">
                        <img src={imageSrcs.sbLogo2} alt="main logo" id="sbLogo2"/>
                    </a>
                </div>
            </div>
            <div id="creditTextContainer">
                <p id="creditText">Site designed by <strong>Andrew Halkerston</strong></p>
            </div>
        </div>
    );
    */
}

const getArrayOfObjectsForReactImage = (imgSrcsArray) => {
    let resArr = [];

    for (let i = 0; i < imgSrcsArray.length; i++) {
        let imgDict = {
            src: imgSrcsArray[i]
        }
        resArr.push(imgDict);
    }

    return resArr;       
}

const getPlaceholderImage = (placeholder) => {
    let style = {
        width: "100%",
        height: "100%"
    }
    return (
        <img src={placeholder} style={style} className="placeholderImage" alt="placeholder"/>
    )
}