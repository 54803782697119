import React, { Component } from 'react';
import './App.css';
import './Mobile.css';
import MainPage from './MainPage'
import MainPageError from './MainPageError'
import MainPageLoading from './MainPageLoading'
import { getImages, getText } from './dataInput';
import { getObjFromSanityCmsCall, getSanityClient} from './utils';
/*
const mainPageInit = (
  <div id="mainContentAllContainer">
    <div id="loadingCopy">
      <h1>Simon Borrough Book Design</h1>
      <h2>About Me</h2>
      <p>I am a freelance book designer with over twenty years experience. I am committed to creating beautifully crafted books in a good humoured but professional way. I would like to think that I am versatile and can produce  a well designed book whatever the purpose, budget or schedule. I believe  in teamwork and acknowledge that I am only one of the cogs that turn to  put a book into print.</p>
      <p>As you will see my clients include children’s book publishers and packagers,  and adult illustrated reference publishers.</p>
      <p>I design one-off sample spreads, covers or complete series designs, and am also happy to layout books based on an existing template or series style and deliver print ready PDFs.</p>
      <p>In 2014 we moved to a 17th century farmouse on the North Devon Coast where I set up my studio overlooking the woods and fields of the Clovelly Estate. When I am not working I like to spend my time exploring the moors and coast of South West England.</p>
      <h2>Children's Books</h2>
      <h2>Adult's Books</h2>
      <h2>Guide Books</h2>
      <h2>Contact</h2>
    </div>
  </div>
);
*/

const mainPageInit = (
  <div></div>
)

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  getMainPage(images, text, mobile) {
    return (
      <MainPage 
      images={images}
      text={text} 
      mobile={mobile}/>
    );
  }

  getMainPageLoading() {
    return (
      <MainPageLoading />
    );
  }

  getMainPageError(text) {
    return (
      <MainPageError 
      text={text} />
    );
  }

  componentWillMount() {

    let mobile;

    if(
      window.screen.width <= 1366
      &&
      window.screen.height <= 1366
    ) {
      mobile = true;
    } else {
      mobile = false;
    }

    let mainPageLoading = this.getMainPageLoading();
    let mainPage = mainPageInit;
    
    this.setState({
      mainPageLoading: mainPageLoading,
      mainPage: mainPage,
      mobile: mobile
    })
  }

  componentDidMount() {
    getSanityClient()
    .then(data => {
      //alert("api data received")
      //console.log("api data received")
      let newObj = getObjFromSanityCmsCall(data);
      let images = getImages(newObj);
      let text = getText(newObj);
      let mainPageError = this.getMainPageError(text);
      let mainPage = this.getMainPage(images, text, this.state.mobile);

      this.setState({
        callData: data,
        images: images,
        text: text,
        mainPage: mainPage,
        mainPageError: mainPageError
      })
    })
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
/*
  componentDidCatch(error, info) {
    // Something happened to one of my children.
    // Add error to state

    let mainPageLoading = this.getMainPageLoading();

    this.setState({
      mainPageLoading: mainPageLoading
    });
*//*
  }
*/
  render() {
    //alert("render called")
    //console.log("render called")

    let result;



    if(
      this.state.callData
      &&
      this.state.images
      &&
      this.state.text
      &&
      !this.state.hasError
    ) {
      result = this.state.mainPage;
    } else if(
      this.state.hasError
    ) {
      result = this.state.mainPageError;
    } else {
      result = this.state.mainPageLoading;
    }
    return this.state.mainPage
  }

  
 /*
  if(
    this.state.callData
    &&
    this.state.images
    &&
    this.state.text
    &&
    !this.state.hasError
  ) {
    result = this.state.mainPage;
  } else if(
    this.state.hasError
  ) {
    result = this.state.mainPageError;
  } else {
    result = null;
  }

    return result;
  }
  */
 /*
  if(
    this.state.callData
    &&
    this.state.images
    &&
    this.state.text
    &&
    !this.state.hasError
  ) {
    result = this.state.mainPage;
  } else if(
    this.state.hasError
  ) {
    result = this.state.mainPageError;
  } else {
    result = null;
  }
    console.log(this.state)
    return result;
  }
  */
}

export default App;
