import React from 'react'
import PageSizePanel from './PageSizePanel'
import { getSettings } from './utils'

export const MainPageLoadingTemplate = (data) => {
    //alert("MainPageLoading render called")
    return (
      <div id="mainContentAllContainer">
        <section id='home'>
            <PageSizePanel  
                panelStyle={data.settings.pageSizePanelStyle}
                id="page1"
                images={data.images.logos}
                backgrounds={data.images.backgrounds}
            />
            {data.text}
        </section>
      </div>
    );
}

const getLoadingText = () => {
    return (
        <div>
            <h1>Simon Borrough Book Design</h1>
            <h2>Freelance with over 20 years experience</h2>
        </div>
    );
}

const MainPageLoading = () => {
    let settings = getSettings();

    let text = getLoadingText();

    let images = {
        backgrounds: {
            page1Background: ""
        },
        logos: {
            sbLogo1: ""
        }
    }

    return(
        <MainPageLoadingTemplate
            images={images}
            settings={settings}
            text={text}
        />
    );
};

export default MainPageLoading